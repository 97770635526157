import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import React, { useRef, useState, MouseEvent } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import 'react-image-crop/src/ReactCrop.scss'
import { useTranslation } from 'react-i18next';

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
interface ImageCropProps {
    key?: string
    previewPhoto?: string | File
    setFieldValue: (field: string, file: File) => void;
    fieldName?: string;
    fieldSetter?: () => void;
    dismissImage?: () => void
    disabled?: boolean
    modalTitle?: string
    index?: number
    handleImage?: (index: number, newPhoto: File | null) => void
    dropItemView?: boolean
    dropItemLink?: string
    aspectProp?: number | undefined
    rejection?: (text: string) => void;
    notBGinfo?:boolean;
}

const ImageCrop: React.FC<ImageCropProps> = ({ key, previewPhoto, setFieldValue, fieldName, fieldSetter, dismissImage, disabled = false, modalTitle, index, handleImage, dropItemView, dropItemLink, aspectProp, rejection, notBGinfo }) => {
    const basePath = process.env.REACT_APP_POS_URL;
    const { t } = useTranslation()
    const [show, setShow] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const [readyImageSrc, setReadyImage] = useState(previewPhoto ? `${basePath}/${previewPhoto}` : dropItemLink || null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(aspectProp || 1 / 1 )
    const [photoError, setPhotoError] = useState(null)
    const handleClick = () => {
        if (disabled) return;
        if (hiddenFileInputRef.current) {
            hiddenFileInputRef.current.click();
        }
    }

    const changePhoto = () => {
        if (disabled) return;
        const handleResetState = () => {
            setImgSrc('')
            setReadyImage(null)
            setCrop(null)
            setCompletedCrop(null)
            setScale(1)
            setRotate(0)
        }
        handleResetState()
        setTimeout(() => {
            if (hiddenFileInputRef.current) {
                hiddenFileInputRef.current.click();
            }
        }, 0)
    }

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size === 0) {
                setPhotoError(t('The image file is empty (0 bytes). Please upload a valid image.'));
                rejection && rejection(t('The image file is empty (0 bytes). Please upload a valid image.'));
                return;
            }
            if (!e.target.files[0].type.match(/^(image\/jpeg|image\/png)$/)) {
                console.warn('Unsupported file type. Only JPEG and PNG are allowed.');
                setPhotoError(t('Please upload a valid png, jpg or jpeg image'));
                rejection && rejection(t('Please upload a valid png, jpg or jpeg image'))
                return;
            }
            console.log(e.target.files[0].size)
            const maxFileSize = 3145728;
            if (e.target.files[0].size > maxFileSize) {
                setPhotoError(t('Image size can\'t be larger than 3 Mb'));
                rejection && rejection(t('Image size can\'t be larger than 3 Mb'))
                return;
            }

            setPhotoError(null);

            setCrop(undefined)
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                setImgSrc(reader.result?.toString() || '')
                handleShow();
            })
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const convertToImage = () => {
        if (disabled) return;
        const canvas = previewCanvasRef.current;
        if (!canvas) return;
        const dataURL = canvas.toDataURL('image/jpeg');
        const blob = dataURLtoBlob(dataURL);
        const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });

        const fileSizeInBytes = file.size; // File size in bytes
        const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2); // Convert to KB and keep 2 decimal places
        const fileSizeInMB = (fileSizeInBytes / (1024 * 1024)).toFixed(2); // Convert to MB and keep 2 decimal places

        setFieldValue(fieldName || 'image', file);
        handleImage && handleImage(index, file)
        if (fieldSetter) fieldSetter();
        setReadyImage(dataURL);
        handleClose();
    };

    function dataURLtoBlob(dataURL: string): Blob {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    async function onDownloadCropClick() {
        if (disabled) return;
        const image = imgRef.current
        const previewCanvas = previewCanvasRef.current
        if (!image || !previewCanvas || !completedCrop) {
            throw new Error('Crop canvas does not exist')
        }
        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: 'image/jpg',
        })

        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current)
        }
        blobUrlRef.current = URL.createObjectURL(blob)

        if (hiddenAnchorRef.current) {
            hiddenAnchorRef.current.href = blobUrlRef.current
            hiddenAnchorRef.current.click()
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const handleResetState = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.preventDefault();
        }
        if (disabled) return;
        if (dismissImage) dismissImage();
        setImgSrc('');
        setReadyImage(null);
        setCrop(null);
        setCompletedCrop(null);
        setScale(1);
        setRotate(0);
        handleImage && handleImage(index, null);
    }

    return (
        <>
            {dropItemView ?
                <div key={key}>
                    <div
                        className={`rounded-1 border-dashed d-flex justify-content-center align-items-center position-relative ${disabled ? 'bg-light' : ''}`}
                        style={{ height: '100px', maxWidth: '100px' }}
                    >
                        {readyImageSrc && (
                            <>
                                <div className="btn-photo position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer">
                                    <a onClick={(event) => handleResetState(event)} className="text-white bg-transparent border-0 cursor-pointer">
                                        <i className="bi bi-trash m-1"></i>
                                    </a>
                                    <a onClick={changePhoto} className="text-white bg-transparent border-0 cursor-pointer ms-2">
                                        <i className="bi bi-pencil m-1"></i>
                                    </a>
                                </div>
                                <img src={readyImageSrc} alt="Uploaded" className="mw-100 mh-100" />
                            </>
                        )}
                        {!readyImageSrc && (
                            <div className='text-center' onClick={handleClick} style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} key={key}>
                                <i className="bi bi-plus-lg"></i>
                                <input
                                    ref={hiddenFileInputRef}
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={onSelectFile}
                                    style={{ display: 'none' }}
                                    disabled={disabled}
                                />

                                <p className='fs-7 mb-0'>{t('Add')}</p>
                            </div>
                        )}
                    </div>
                </div>
                :
                <Row className="mb-4 mb-md-0">
                    <Col xs={6} sm={5} md={12} className="mb-md-3">
                        <div
                             onClick={handleClick}
                             className={`input-with-image rounded-1 border d-flex justify-content-center align-items-center py-3 px-2 w-100 h-100 ${disabled ? 'bg-light' : ''}`}
                             style={{ cursor: readyImageSrc ? '' : 'pointer' }}
                        >
                            {
                                readyImageSrc ?
                                    <img style={{ maxWidth: '100%', objectFit: 'contain' }} src={readyImageSrc} alt=" Image" />
                                    :
                                    <div className='text-center'  style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
                                        <i className="bi bi-plus-lg"></i>
                                        <input
                                            ref={hiddenFileInputRef}
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={onSelectFile}
                                            style={{ display: 'none' }}
                                            disabled={disabled}
                                        />
                                        <p className='d-none d-md-block'>{t('Add')}</p>
                                    </div>
                            }
                        </div>
                    </Col>
                    <Col xs={6} sm={7} md={12}>
                        <div id="form_logoFeedback">
                            {photoError && <p className="fs-7 m-0 p-1 text-danger">
                                {photoError}
                            </p>}
                            {!notBGinfo
                              ?
                                <p className="fs-7 text-grey mb-0 ps-1">
                                    {t("Should be placed on a white or transparent background.")}
                                </p>
                              : null
                            }
                            <p className="fs-7 text-grey mb-0 ps-1">{t('Maximum size: 3 MB')}</p>
                        </div>
                        {
                            readyImageSrc &&
                            <div className="mt-4 ps-1 d-flex flex-column flex-sm-row">
                                <a onClick={changePhoto} className={`btn btn-outline-primary py-1 px-3 fw-normal rounded-1 ${disabled ? 'disabled' : ''}`}>
                                    {t('Change')}
                                </a>
                                <Button variant="outline-primary" className="ms-sm-4 py-1 px-2 mt-3 mt-sm-0 rounded-1" onClick={(event) => handleResetState(event)} disabled={disabled}>
                                    <span className="d-sm-none fw-normal">{t("Delete")}</span>
                                    <i className="bi bi-trash align-middle d-none d-sm-block"></i>
                                </Button>
                            </div>
                        }
                    </Col>
                </Row>

            }

            {!!completedCrop && (
                <>
                    <div key={key}>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                display: 'none',
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                </>
            )}

            <Modal show={show} key={key}
                onHide={() => {
                    handleClose()
                }}>
                <Modal.Header closeButton>
                    <Modal.Title> {modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            // minWidth={400}
                            minHeight={100}
                        // circularCrop
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className='w-100' onClick={convertToImage} disabled={disabled}>
                        {t('save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ImageCrop;
