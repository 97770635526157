import React from 'react';
import {
    Modal,
    Button,
    Col,
    Row,
    OverlayTrigger,
    Tooltip,
    Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import DurationInputCustomModal from './DurationInputCustomModal';
import { currentBranchIdVar } from '@me-team/host/src/apollo/globalVar/state';
import { useReactiveVar } from '@apollo/client';
import {
    useCreateCustomDayMutation, useDeleteCustomDayMutation,
    useGetEmployeeByIdScheduleQuery, useUpdateCustomDayMutation,
} from '@me-team/host/main/work-shedule/graphql/workShedule.hooks';

import { DateTime, DateObjectUnits } from 'luxon';

type CustomDayModalProps = {
    show: boolean;
    toggleModal: () => void;
    date?: DateObjectUnits;
    resource: any;
};
interface Range {
    startTime?: string;
    endTime?: string;
}

interface FormValues {
    ranges: Range[];
}
const CustomDayModal: React.FC<CustomDayModalProps> = ({
                                                           show,
                                                           toggleModal,
                                                           date,
                                                           resource,
                                                       }) => {
    const employeeId = resource?.id || resource?.resource;
    const { t, i18n } = useTranslation();
    const branchId = useReactiveVar(currentBranchIdVar);
    const { data, refetch } = useGetEmployeeByIdScheduleQuery({
        variables: { employeeId },
        context: {
            errorType: 'global',
        },
    });
    const dateWork = DateTime.fromObject(date);
    const branchName = data?.user?.company?.branches[0].name;
    const employee = data?.user?.company?.employees?.employees[0];

    const [createCustomDay] = useCreateCustomDayMutation({
        context: {
            errorType: 'local',
        },
    });

    const [updateCustomDay] = useUpdateCustomDayMutation({
        context: {
            errorType: 'local',
        },
    });
    const [deleteCustomDay] = useDeleteCustomDayMutation({
        context: {
            errorType: 'local',
        },
    });

    const formattedDate = dateWork.toFormat('yyyy-MM-dd');

    const matchedCustomDays = employee?.customDays.filter((customDay) => {
        const customDayDate = customDay.date.split(' ')[0];
        return customDayDate === formattedDate;
    });

    const existingRanges = React.useMemo(() => {
        if (matchedCustomDays && matchedCustomDays.length > 0) {
            const allPeriods = matchedCustomDays.flatMap((customDay) =>
                customDay.customDayPeriods.map((period) => ({
                    customDayId: customDay.id,
                    customDayPeriodId: period.id,
                    startTime: period.startTime,
                    endTime: period.endTime,
                }))
            );
            return allPeriods;
        }
        return [{ startTime: '', endTime: '' }];
    }, [matchedCustomDays]);

    const handleSave = async (values: FormValues) => {

        try {
            if (matchedCustomDays && matchedCustomDays.length > 0) {
                const customDayId = matchedCustomDays[0].id;
                if (values.ranges.length > 0) {
                    await updateCustomDay({
                        variables: {
                            customDayId: customDayId,
                            input: {
                                customDayPeriods: values.ranges.map((range: Range) => ({
                                    startTime: range.startTime,
                                    endTime: range.endTime,
                                })),
                            },
                        },
                    });
                } else {
                    await deleteCustomDay({
                        variables: { customDayId },
                    });
                }
            } else {
                await createCustomDay({
                    variables: {
                        employeeId: resource?.id,
                        branchId: +branchId,
                        input: {
                            date: formattedDate,
                            customDayPeriods: values.ranges.map((range) => ({
                                startTime: range.startTime,
                                endTime: range.endTime,
                            })),
                        },
                    },
                });
            }
            refetch();
            toggleModal();
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    const validationSchema = Yup.object().shape({
        ranges: Yup.array().of(
            Yup.object()
                .shape({
                    startTime: Yup.string(),
                    endTime: Yup.string(),
                })
                .test(
                    'is-greater',
                    'Время завершения должно быть позже времени начала',
                    function (value) {
                        const { startTime, endTime } = value;
                        if (startTime && endTime) {
                            const start = DateTime.fromISO(`1970-01-01T${startTime}`);
                            const end = DateTime.fromISO(`1970-01-01T${endTime}`);
                            return end > start;
                        }
                        return true;
                    }
                )
        ),
    });

    return (
        <Modal
            show={show}
            onHide={toggleModal}
            centered
            className="modal"
            id="modalContentContainer"
            tabIndex="-1"
            role="dialog"
        >
            <Modal.Header closeButton className="border-0">
                <h5 className="m-0">{t('Set Custom Day')}</h5>
            </Modal.Header>
            <Formik<FormValues>
                initialValues={{
                    ranges: existingRanges,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSave}
                enableReinitialize
            >
                {({ values, errors, touched }) => (
                    <Form>
                        <Modal.Body>
                            <Row className="py-3 m-0">
                                <Col sm={3}>
                                    <strong>
                                        <span className="text-dark fs-7">
                                            {t('Employee')}
                                        </span>
                                    </strong>
                                </Col>
                                <Col sm={9}>
                                    <span className="text-dark fs-7">
                                        {employee?.name} {employee?.surname}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="py-3 m-0">
                                <Col sm={3}>
                                    <strong>
                                        <span className="text-dark fs-7">
                                            {t('Branch')}
                                        </span>
                                    </strong>
                                </Col>
                                <Col sm={9}>
                                    <span className="text-dark fs-7">
                                        {branchName}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="py-3 m-0">
                                <Col sm={3}>
                                    <strong>
                                        <span className="text-dark fs-7">
                                            {t('Date')}
                                        </span>
                                    </strong>
                                </Col>
                                <Col sm={9}>
                                    <span className="text-dark fs-7">
                                        {dateWork
                                            .setLocale(i18n.language)
                                            .toFormat('ccc, dd LLLL yyyy')}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="py-3 m-0">
                                <Col sm={3}>
                                    <strong>
                                        <span className="text-dark fs-7">
                                            {t('Working hours')}
                                        </span>
                                    </strong>
                                </Col>
                                <Col sm={9}>
                                    <FieldArray name="ranges">
                                        {(arrayHelpers) => {
                                            if (values.ranges.length === 0) {
                                                arrayHelpers.push({
                                                    startTime: '',
                                                    endTime: '',
                                                });
                                            }

                                            return (
                                                <>
                                                    {values.ranges &&
                                                        values.ranges.length > 0 &&
                                                        values.ranges.map(
                                                            (range, index: number) => (
                                                                <Row
                                                                    key={index}
                                                                    className={`align-items-center ${
                                                                        index !== 0
                                                                            ? 'pt-3'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <Col
                                                                        md={10}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        <Container className="p-0">
                                                                            <DurationInputCustomModal
                                                                                name={`ranges[${index}].startTime`}
                                                                            />
                                                                        </Container>
                                                                        <i className="bi bi-dash mx-1"></i>
                                                                        <Container className="p-0">
                                                                            <DurationInputCustomModal
                                                                                name={`ranges[${index}].endTime`}
                                                                            />
                                                                        </Container>
                                                                    </Col>
                                                                    <Col
                                                                        md={2}
                                                                        className="d-flex justify-content-between align-items-center ps-0"
                                                                    >
                                                                        {values.ranges
                                                                                .length >
                                                                            0 && (
                                                                                <OverlayTrigger
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            {t(
                                                                                                'Remove range'
                                                                                            )}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        onClick={() =>
                                                                                            arrayHelpers.remove(
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        className="bi bi-trash cursor-pointer"
                                                                                    ></i>
                                                                                </OverlayTrigger>
                                                                            )}
                                                                        {index ===
                                                                            values
                                                                                .ranges
                                                                                .length -
                                                                            1 && (
                                                                                <OverlayTrigger
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            {t(
                                                                                                'Add range'
                                                                                            )}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className="bi bi-plus-circle cursor-pointer"
                                                                                        onClick={() =>
                                                                                            arrayHelpers.push(
                                                                                                {
                                                                                                    startTime:
                                                                                                        '',
                                                                                                    endTime:
                                                                                                        '',
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    ></i>
                                                                                </OverlayTrigger>
                                                                            )}
                                                                    </Col>
                                                                    <Col
                                                                        md={10}
                                                                        className="d-flex align-items-center"
                                                                    >
                                                                        {errors.ranges &&
                                                                            errors.ranges[index] &&
                                                                            touched.ranges &&
                                                                            touched.ranges[index] && (
                                                                                <p className="fs-7 p-1 text-danger m-0">{errors.ranges[index]}</p>
                                                                            )}
                                                                    </Col>

                                                                </Row>
                                                            )
                                                        )}
                                                </>
                                            );
                                        }}
                                    </FieldArray>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="border-0">
                            <Col>
                                <Button
                                    variant="outline-primary"
                                    type="button"
                                    className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                    onClick={toggleModal}
                                >
                                    {t('cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100 text-truncate"
                                >
                                    {matchedCustomDays?.length > 0
                                        ? t('Edit')
                                        : t('save')}
                                </Button>
                            </Col>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default CustomDayModal;
