import {TFunction} from 'i18next';
import LogbookBranch from "../../images/branches/logbook-branch.png";
import EmployeeBranch from "../../images/branches/employee-branch.png";
import ServiceBranch from "../../images/branches/service-branch.png";
import ClientsBranch from "../../images/branches/clients-branch.png";
import OnlineBranch from "../../images/branches/online-branch.png";
import TariffBranch from "../../images/branches/tariff-branch.png";
import WarehouseBranch from "../../images/branches/warehouse-branch.png";
// import MenuBranch from "../../images/branches/menu-branch.png";
// import DishesBranch from "../../images/branches/dishes-branch.png";

interface GetSectionsProps {
    t: TFunction;
    userRole?: number | string;
    basePath: string;
    currentBranchId: number | string;
    currentCompanyId: number | string;
    hasWarehouseModule?: boolean;
}

export const getSections = ({ t, userRole, basePath, currentBranchId, currentCompanyId, hasWarehouseModule }: GetSectionsProps) => {
    return [
        {
            id: 1,
            title: t("Logbook"),
            linkLabel: t("Add record"),
            navigateTo: '/logbook/add-record',
            items: [
                { label: t('Logbook'), to: `/logbook/journal/${currentBranchId}` },
                { label: t('Booking Statistic'), to: `${basePath}/booking/statistic/branch/${currentBranchId}` }
            ],
            image: LogbookBranch,
        },
        {
            id: 2,
            title: t("Personnel management"),
            linkLabel: t("Work schedule"),
            navigateTo: `${basePath}/personnel/schedule/${currentCompanyId}/${currentBranchId}/employee`,
            items: [
                { label: t('Employees'), to: `/employees-list` },
                userRole !== 5 && { label: t('Add an employee'), to: `/employees-list/add-employee` },
                { label: t('Positions'), to: `/positions` },
                { label: t('Reviews'), to: `/booking/review/company/${currentCompanyId}` },
                { label: t('Salary'), to: `/personnel/${currentCompanyId}/salary` },
                userRole !== 5 && { label: t('History of Payments'), to: `/personnel/${currentCompanyId}/payment-history` }
            ],
            image: EmployeeBranch,
        },
        {
            id: 3,
            title: t("Services"),
            linkLabel: t("Service list"),
            navigateTo: `/services/service/${currentCompanyId}/service-list`,
            items: [
                userRole !== 5 && { label: t('Add a service'), to: `/services/service/${currentCompanyId}/new` },
                { label: t('Service Categories'), to: '/service-categories' },
            ],
            image: ServiceBranch,
        },
        {
            id: 4,
            title: t("Clients"),
            linkLabel: t("Add client"),
            navigateTo: `/clients/${currentCompanyId}/new`,
            isHidden: userRole === 5,
            items: [
                { label: t('Client list'), to: `/clients/${currentCompanyId}/list` },
                { label: t('History of visits'), to: '/clients-history-visit' }
            ],
            image: ClientsBranch,
        },
        {
            id: 5,
            title: t("Online Booking"),
            linkLabel: t("View"),
            navigateTo: `/booking/company/${currentCompanyId}/config`,
            items: [
                { label: t('Online Booking Config'), to: `/booking/company/${currentCompanyId}/config` }
            ],
            image: OnlineBranch,
        },
        ...(
            !(userRole === 5 || userRole === 3)
                ? [{
                    id: 6,
                    title: t("Management of Tariffs"),
                    linkLabel: t("Tariff Plans"),
                    navigateTo: `/payment/company/${currentCompanyId}/tariff`,
                    items: [
                        { label: t('Modules'), to: `${basePath}/payment/module/company/${currentCompanyId}` },
                        { label: t('Payment History'), to: `/payment/history/company/${currentCompanyId}` },
                    ],
                    image: TariffBranch,
                }] : []
        ),
        {
            id: 7,
            title: t("Warehouse accounting"),
            linkLabel: t("Warehouses"),
            navigateTo: `${basePath}/warehouse/storage/branch/${currentBranchId}`,
            hasWarehouseModule: hasWarehouseModule,
            items: [
                { label: t('Products'), to: `${basePath}/warehouse/product/${currentCompanyId}` },
                userRole !== 5 && { label: t('Add product'), to: `${basePath}/warehouse/${currentCompanyId}/new-product` },
                { label: t('Technological maps'), to: `${basePath}/warehouse/technologicalMap/${currentCompanyId}` },
                { label: t('Reports'), to: `${basePath}/warehouse/reports/branch/${currentBranchId}` },
                { label: t('Product categories'), to: `${basePath}/warehouse/goods/${currentCompanyId}/category` },
                { label: t('Technological map categories'), to: `${basePath}/warehouse/technologicalMapCategory/${currentCompanyId}` }
            ],
            image: WarehouseBranch,
        },
        // {
        //     title: t("menu"),
        //     navigateTo: "/menu",
        //     linkLabel: t("All menu"),
        //     items: [
        //         { label: t("Create menu"), to: "#" },
        //     ],
        //     showWatchAll: true,
        //     showCreate: true,
        //     image: MenuBranch
        // },
        // {
        //     title: t("Dishes"),
        //     navigateTo: '/dishes',
        //     linkLabel: t("List of dishes"),
        //     items: [
        //         { label: t("Add dish"), to: "#" },
        //         { label: t("Dishes categories"), to: "#" },
        //         { label: t("Dishes additives"), to: "#" },
        //     ],
        //     showWatchAll: true,
        //     showCreate: true,
        //     image: DishesBranch
        // },
    ];
};