import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";

const BookingModalStep: React.FC<StepFunctions> = () => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <Row>
                    <Col xs={11}>
                        <p className='mb-0 fs-7'>  <span className="fs-normal fw-bold fs-7"> Ввімкніть опцію “Онлайн-Запис” </span>щоб дозволити показувати співробітника та його розклад доступний у формі онлайн запису та <span className="fs-normal fw-bold fs-7"> натисніть “Зберегти”</span></p>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg "></i>
                        </Button>
                    </Col>
                </Row>
            )}

        </div>
    )
}

export default BookingModalStep;