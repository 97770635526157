import React, {useEffect, useState} from 'react';
import {
  FiltersButton,
  InfoBanner,
  ReactSelect,
  TableHeader
} from '@services/ui-components';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ReviewsTable from './ReviewsTable';
import {getReviewTypeOptions, getSortOptions, ratingOptions} from '@me-team/host/src/utils/utils';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {
  currentCompanyIdVar, currentUserEmailVar,
  currentUserRoleVar,
  userIdVar
} from '@me-team/host/src/apollo/globalVar/state';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../services/ErrorService';
import {Filter, initialValueFilter} from './constants';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {UserRole} from '../../utils/enums';
import {
  useGetModulesForCompanyQuery,
  useGetModulesListQuery
} from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import { useAddModuleMutation } from '@me-team/host/main/salary/graphql/salary.hooks';
import { useGetEmployeesForReviewsQuery } from '@me-team/host/main/reviews/graphql/reviews.hooks';

interface Option {
  value: any;
  label: string;
}

const Reviews: React.FC = () => {
  const {t, i18n} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentUserEmail = useReactiveVar(currentUserEmailVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const reviewTypeOptions = getReviewTypeOptions(t);
  const sortOptions = getSortOptions(t);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {data: modulesList} = useGetModulesListQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const {data: modulesForCompanyData} = useGetModulesForCompanyQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const onlineBookingModuleId = modulesList && modulesList?.relations?.modules?.find(module => module.name === "Online Booking");
  const reviewsModuleId = modulesList && modulesList?.relations?.modules?.find(module => module.name === "Reviews");
  const isConnectModuleOnlineBooking = modulesForCompanyData && modulesForCompanyData?.user?.company?.modules?.find(module => module.name === "Online Booking");

  const [updateCompanyModuleRelation] = useAddModuleMutation()
  useEffect(() => {
    if (onlineBookingModuleId && !isEmployeeRole) {
      updateCompanyModuleRelation({
        variables: {
          companyId: currentCompanyId && +currentCompanyId,
          input: {
            id: onlineBookingModuleId && +onlineBookingModuleId?.id,
            isActive: true
          }
        },
        context: {
          errorType: 'local'
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      })
    }
  }, [currentCompanyId, onlineBookingModuleId, userRole]);
  useEffect(() => {
    if (reviewsModuleId && isConnectModuleOnlineBooking && !isEmployeeRole) {
      updateCompanyModuleRelation({
        variables: {
          companyId: currentCompanyId && +currentCompanyId,
          input: {
            id: reviewsModuleId && +reviewsModuleId?.id,
            isActive: true
          }
        },
        onCompleted: (data) => {
        },
        context: {
          errorType: 'global'
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      })
    }
  }, [currentCompanyId, reviewsModuleId, isConnectModuleOnlineBooking, userRole]);

  const {data: employeesData, loading, error} = useGetEmployeesForReviewsQuery({
    variables: {
      itemsPerPage: 1000,
      filterByNameAndPosition : true,
      email: isEmployeeRole ? currentUserEmail : null
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee) => {
    return {
      value: employee.id,
      label: `${employee.name} ${employee.surname} (${employee.position.name})`
    }
  }) || [];

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [selectedReviewType, setSelectedReviewType] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedReviewEmployees, setSelectedReviewEmployees] = useState(null);
  const [selectedSort, setSelectedSort] = useState({
    label: t("New first"),
    value: ['createdAt', 'DESC']
  });

  useEffect(() => {
    setSelectedSort({
      label: t("New first"),
      value: ['createdAt', 'DESC']
    });
  }, [i18n.language]);

  const [showFilters, setShowFilters] = useState(false);
  const [filter, setFilter] = useState<Filter>(initialValueFilter);

  const handleCreateReview = () => {
    setShowCreateModal(true);
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  const handleReviewTypeChange = (selectedOption: Option) => {
    setSelectedReviewType(selectedOption);
  };

  const handleRatingChange = (selectedOption: Option) => {
    setSelectedRating(selectedOption);
  };

  const handleEmployeesChange = (selectedOption: Option) => {
    setSelectedReviewEmployees(selectedOption);
  };

  const handleSortChange = (selectedOption: Option) => {
    setSelectedSort(selectedOption);
  };

  const handleApplyFilter = () => {
    setFilter({
      employeeId: selectedReviewEmployees?.value ? selectedReviewEmployees?.value : null,
      reviewType: selectedReviewType?.value ? selectedReviewType?.value : null,
      rating: selectedRating?.value ? selectedRating?.value : null,
      reviewSortField: selectedSort?.value && selectedSort?.value[0],
      order: selectedSort?.value && selectedSort?.value[1]
    });
  };

  const handleClearFilter = () => {
    setFilter({
      employeeId: null,
      reviewType: null,
      rating: null,
      reviewSortField: 'createdAt',
      order: "DESC"
    });
    setSelectedReviewType(null)
    setSelectedRating(null)
    setSelectedReviewEmployees(null)
    setSelectedSort({label: t("New first"), value: ['createdAt', 'DESC']})
  };

  if (loading) return <Spinner/>;

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p
          className='fs-7 mb-0'>{t('You can view all reviews on this page')}
        </p>
      </InfoBanner>

      <TableHeader
        title={t('List of reviews')}
        paths={[
          {
            path: `/booking/review/company/${currentCompanyId}`,
            label: t('Personnel management')
          },
          {
            path: `/booking/review/company/${currentCompanyId}`,
            label: t('List of reviews')
          },
        ]}
        colSize={9}
        btnColSize={3}
        buttons={
          <Row
            className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
            {!isEmployeeRole
              ?
              <Col
                xs={12}
                md={6}
                lg={7}
              >
                <Button
                  variant='primary'
                  className='w-100  mb-3 mb-lg-0 '
                  onClick={handleCreateReview}>
                  <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                  {t('Add Review')}
                </Button>
              </Col>
              : null
            }
            <Col
              xs={12}
              md={6}
              lg={5}
              className='px-0'
            >
              <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters} classes="mb-3 mb-lg-0"/>
            </Col>
          </Row>
        }
        showFilters={showFilters}>
        <Row className='gx-3'>
          <Col lg={9}>
            <Row className='gx-3 h-100'>
              <Col
                lg={3}
                className='text-primary'>
                <ReactSelect
                  name='employeeId'
                  id='reviews-employeeId-field'
                  value={selectedReviewEmployees}
                  options={employeesOptions}
                  placeholder={t('Review modal and table.Employee')}
                  onChange={handleEmployeesChange}
                  isSearchable={true}
                  disabled={isEmployeeRole}
                />
              </Col>
              <Col
                lg={3}
                className='text-primary'>
                <ReactSelect
                  name='reviewType'
                  id='reviews-reviewType-field'
                  value={selectedReviewType}
                  options={reviewTypeOptions}
                  placeholder={t('Review Type')}
                   onChange={handleReviewTypeChange}
                  isSearchable={false}
                />
              </Col>
              <Col
                lg={3}
                className='text-primary'
              >
                <ReactSelect
                  name='reviewSortField'
                  id='reviews-reviewSortField-field'
                  value={selectedRating}
                  options={ratingOptions}
                  placeholder={t('Review modal and table.Rating')}
                  onChange={handleRatingChange}
                  isSearchable={false}
                />
              </Col>
              <Col
                lg={3}
                className='text-primary'>
                <ReactSelect
                  name='order'
                  id='reviews-order-field'
                  value={selectedSort}
                  options={sortOptions}
                  placeholder={t('Sorting')}
                  onChange={handleSortChange}
                  isSearchable={false}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            <Row className='gx-3 h-100'>
              <Col
                lg={6}
                className='mb-3 mb-lg-0'>
                <button
                  type='button'
                  className='btn btn-outline-dark border-grey w-100 h-100  fw-normal'
                  onClick={handleClearFilter}>
                  {t('clear')}
                </button>
              </Col>
              <Col
                lg={6}
                className='mb-3 mb-lg-0'>
                <button
                  className='btn btn-primary w-100 h-100'
                  onClick={handleApplyFilter}>
                  {t('apply')}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </TableHeader>
      <ReviewsTable
        filter={filter}
        setShowCreateModal={setShowCreateModal}
        showCreateModal={showCreateModal}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default Reviews;
