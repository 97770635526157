/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CreateVacationDocument = gql`
    mutation CreateVacation($employeeId: Int!, $input: VacationInput!) {
  createVacation(employeeId: $employeeId, input: $input) {
    id
    startDate
    endDate
  }
}
    `;
export type CreateVacationMutationFn = Apollo.MutationFunction<CreateVacationMutation, CreateVacationMutationVariables>;

/**
 * __useCreateVacationMutation__
 *
 * To run a mutation, you first call `useCreateVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacationMutation, { data, loading, error }] = useCreateVacationMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVacationMutation(baseOptions?: Apollo.MutationHookOptions<CreateVacationMutation, CreateVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVacationMutation, CreateVacationMutationVariables>(CreateVacationDocument, options);
      }
export type CreateVacationMutationHookResult = ReturnType<typeof useCreateVacationMutation>;
export type CreateVacationMutationResult = Apollo.MutationResult<CreateVacationMutation>;
export type CreateVacationMutationOptions = Apollo.BaseMutationOptions<CreateVacationMutation, CreateVacationMutationVariables>;
export const DeleteVacationDocument = gql`
    mutation DeleteVacation($vacationId: Int!) {
  deleteVacation(vacationId: $vacationId)
}
    `;
export type DeleteVacationMutationFn = Apollo.MutationFunction<DeleteVacationMutation, DeleteVacationMutationVariables>;

/**
 * __useDeleteVacationMutation__
 *
 * To run a mutation, you first call `useDeleteVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacationMutation, { data, loading, error }] = useDeleteVacationMutation({
 *   variables: {
 *      vacationId: // value for 'vacationId'
 *   },
 * });
 */
export function useDeleteVacationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVacationMutation, DeleteVacationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVacationMutation, DeleteVacationMutationVariables>(DeleteVacationDocument, options);
      }
export type DeleteVacationMutationHookResult = ReturnType<typeof useDeleteVacationMutation>;
export type DeleteVacationMutationResult = Apollo.MutationResult<DeleteVacationMutation>;
export type DeleteVacationMutationOptions = Apollo.BaseMutationOptions<DeleteVacationMutation, DeleteVacationMutationVariables>;
export const GetEmployeeByIdScheduleDocument = gql`
    query GetEmployeeByIdSchedule($employeeId: Int!) {
  user {
    id
    company {
      branches {
        id
        name
      }
      employees(id: $employeeId, onlyActive: true) {
        employees {
          id
          name
          surname
          patronymic
          email
          vacations {
            id
            startDate
            endDate
          }
          customDays {
            id
            date
            customDayPeriods {
              id
              startTime
              endTime
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeByIdScheduleQuery__
 *
 * To run a query within a React component, call `useGetEmployeeByIdScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeByIdScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeByIdScheduleQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmployeeByIdScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables> & ({ variables: GetEmployeeByIdScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables>(GetEmployeeByIdScheduleDocument, options);
      }
export function useGetEmployeeByIdScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables>(GetEmployeeByIdScheduleDocument, options);
        }
export function useGetEmployeeByIdScheduleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables>(GetEmployeeByIdScheduleDocument, options);
        }
export type GetEmployeeByIdScheduleQueryHookResult = ReturnType<typeof useGetEmployeeByIdScheduleQuery>;
export type GetEmployeeByIdScheduleLazyQueryHookResult = ReturnType<typeof useGetEmployeeByIdScheduleLazyQuery>;
export type GetEmployeeByIdScheduleSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeByIdScheduleSuspenseQuery>;
export type GetEmployeeByIdScheduleQueryResult = Apollo.QueryResult<GetEmployeeByIdScheduleQuery, GetEmployeeByIdScheduleQueryVariables>;
export const CreateCustomDayDocument = gql`
    mutation CreateCustomDay($employeeId: Int!, $branchId: Int!, $input: CustomDayInput!) {
  createCustomDay(employeeId: $employeeId, branchId: $branchId, input: $input) {
    id
    date
  }
}
    `;
export type CreateCustomDayMutationFn = Apollo.MutationFunction<CreateCustomDayMutation, CreateCustomDayMutationVariables>;

/**
 * __useCreateCustomDayMutation__
 *
 * To run a mutation, you first call `useCreateCustomDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomDayMutation, { data, loading, error }] = useCreateCustomDayMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      branchId: // value for 'branchId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomDayMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomDayMutation, CreateCustomDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomDayMutation, CreateCustomDayMutationVariables>(CreateCustomDayDocument, options);
      }
export type CreateCustomDayMutationHookResult = ReturnType<typeof useCreateCustomDayMutation>;
export type CreateCustomDayMutationResult = Apollo.MutationResult<CreateCustomDayMutation>;
export type CreateCustomDayMutationOptions = Apollo.BaseMutationOptions<CreateCustomDayMutation, CreateCustomDayMutationVariables>;
export const UpdateCustomDayDocument = gql`
    mutation UpdateCustomDay($customDayId: Int!, $input: UpdateCustomDayInput!) {
  updateCustomDay(customDayId: $customDayId, input: $input) {
    id
    date
  }
}
    `;
export type UpdateCustomDayMutationFn = Apollo.MutationFunction<UpdateCustomDayMutation, UpdateCustomDayMutationVariables>;

/**
 * __useUpdateCustomDayMutation__
 *
 * To run a mutation, you first call `useUpdateCustomDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomDayMutation, { data, loading, error }] = useUpdateCustomDayMutation({
 *   variables: {
 *      customDayId: // value for 'customDayId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomDayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomDayMutation, UpdateCustomDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomDayMutation, UpdateCustomDayMutationVariables>(UpdateCustomDayDocument, options);
      }
export type UpdateCustomDayMutationHookResult = ReturnType<typeof useUpdateCustomDayMutation>;
export type UpdateCustomDayMutationResult = Apollo.MutationResult<UpdateCustomDayMutation>;
export type UpdateCustomDayMutationOptions = Apollo.BaseMutationOptions<UpdateCustomDayMutation, UpdateCustomDayMutationVariables>;
export const DeleteCustomDayDocument = gql`
    mutation DeleteCustomDay($customDayId: Int!) {
  updateCustomDay(customDayId: $customDayId, input: {customDayPeriods: []}) {
    id
    date
  }
}
    `;
export type DeleteCustomDayMutationFn = Apollo.MutationFunction<DeleteCustomDayMutation, DeleteCustomDayMutationVariables>;

/**
 * __useDeleteCustomDayMutation__
 *
 * To run a mutation, you first call `useDeleteCustomDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomDayMutation, { data, loading, error }] = useDeleteCustomDayMutation({
 *   variables: {
 *      customDayId: // value for 'customDayId'
 *   },
 * });
 */
export function useDeleteCustomDayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomDayMutation, DeleteCustomDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomDayMutation, DeleteCustomDayMutationVariables>(DeleteCustomDayDocument, options);
      }
export type DeleteCustomDayMutationHookResult = ReturnType<typeof useDeleteCustomDayMutation>;
export type DeleteCustomDayMutationResult = Apollo.MutationResult<DeleteCustomDayMutation>;
export type DeleteCustomDayMutationOptions = Apollo.BaseMutationOptions<DeleteCustomDayMutation, DeleteCustomDayMutationVariables>;
export const GetEmployeeForCheckEmptyListDocument = gql`
    query GetEmployeeForCheckEmptyList($id: Int, $positionId: Int) {
  user {
    id
    company {
      employees(
        itemsPerPage: 1000
        id: $id
        positionId: $positionId
        onlyActive: true
      ) {
        employees {
          id
          name
          surname
          patronymic
          email
          status {
            id
            name
          }
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeForCheckEmptyListQuery__
 *
 * To run a query within a React component, call `useGetEmployeeForCheckEmptyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeForCheckEmptyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeForCheckEmptyListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useGetEmployeeForCheckEmptyListQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>(GetEmployeeForCheckEmptyListDocument, options);
      }
export function useGetEmployeeForCheckEmptyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>(GetEmployeeForCheckEmptyListDocument, options);
        }
export function useGetEmployeeForCheckEmptyListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>(GetEmployeeForCheckEmptyListDocument, options);
        }
export type GetEmployeeForCheckEmptyListQueryHookResult = ReturnType<typeof useGetEmployeeForCheckEmptyListQuery>;
export type GetEmployeeForCheckEmptyListLazyQueryHookResult = ReturnType<typeof useGetEmployeeForCheckEmptyListLazyQuery>;
export type GetEmployeeForCheckEmptyListSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeForCheckEmptyListSuspenseQuery>;
export type GetEmployeeForCheckEmptyListQueryResult = Apollo.QueryResult<GetEmployeeForCheckEmptyListQuery, GetEmployeeForCheckEmptyListQueryVariables>;
export type CreateVacationMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  input: Types.VacationInput;
}>;


export type CreateVacationMutation = { __typename?: 'Mutation', createVacation: { __typename?: 'VacationProxy', id: number, startDate?: string | null, endDate?: string | null } };

export type DeleteVacationMutationVariables = Types.Exact<{
  vacationId: Types.Scalars['Int']['input'];
}>;


export type DeleteVacationMutation = { __typename?: 'Mutation', deleteVacation: boolean };

export type GetEmployeeByIdScheduleQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type GetEmployeeByIdScheduleQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', branches?: Array<{ __typename?: 'BranchProxy', id: number, name: string }> | null, employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, patronymic?: string | null, email: string, vacations?: Array<{ __typename?: 'VacationProxy', id: number, startDate?: string | null, endDate?: string | null }> | null, customDays?: Array<{ __typename?: 'CustomDayProxy', id: number, date?: string | null, customDayPeriods?: Array<{ __typename?: 'CustomDayPeriodProxy', id: number, startTime?: string | null, endTime?: string | null }> | null }> | null }> | null } | null } | null } | null };

export type CreateCustomDayMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  branchId: Types.Scalars['Int']['input'];
  input: Types.CustomDayInput;
}>;


export type CreateCustomDayMutation = { __typename?: 'Mutation', createCustomDay: { __typename?: 'CustomDayProxy', id: number, date?: string | null } };

export type UpdateCustomDayMutationVariables = Types.Exact<{
  customDayId: Types.Scalars['Int']['input'];
  input: Types.UpdateCustomDayInput;
}>;


export type UpdateCustomDayMutation = { __typename?: 'Mutation', updateCustomDay?: { __typename?: 'CustomDayProxy', id: number, date?: string | null } | null };

export type DeleteCustomDayMutationVariables = Types.Exact<{
  customDayId: Types.Scalars['Int']['input'];
}>;


export type DeleteCustomDayMutation = { __typename?: 'Mutation', updateCustomDay?: { __typename?: 'CustomDayProxy', id: number, date?: string | null } | null };

export type GetEmployeeForCheckEmptyListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  positionId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetEmployeeForCheckEmptyListQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, patronymic?: string | null, email: string, status?: { __typename?: 'EmployeeStatusProxy', id: number, name?: string | null } | null, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null }> | null } | null } | null } | null };
