import React, { useEffect, useRef, useState } from 'react';
import {Col, Form} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CategoryPickerCategory, CategoryPickerComponent } from '@me-pos/category-picker';
import CategoryService, { CategoryInterface} from "../../../services/serviceCategory/ServicesCategoryNewInnit";
import { ApolloError } from '@apollo/client';
import i18n from "@me-team/host/src/i18n";
import { useGetCategoryServicesNewQuery } from '@me-team/host/main/modalSalary/graphql/modalSalary.hooks';

interface CategoryInputProps {
    label?: string;
    name?: string;
    valueCategory?: number;
    valueSubCategory?: number;
    nameCategory?: string;
    nameSubCategory?: string;
    currentId: any;
    setCurrentId?: any;
    currentName: string;
    setCurrentName: React.Dispatch<React.SetStateAction<string>>;
}

const CategoryInput: React.FC<CategoryInputProps> = ({
    valueCategory,
    valueSubCategory,
    currentId,
    setCurrentId,
    setCurrentName,
    currentName
}) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);

    const [categoryList, setCategoryList] = useState<CategoryInterface[]>(null);
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const { data: categoryData, loading, error } = useGetCategoryServicesNewQuery({
        onCompleted: (data) => {
            data?.user?.company?.serviceCategoriesNew &&
            setCategoryList(
                CategoryService.categoryModuleCreator(
                    data?.user?.company?.serviceCategoriesNew,
                    valueCategory ? valueCategory : null,
                    valueSubCategory ? valueSubCategory : null
                )
            );
        },
        onError: (error: ApolloError) => {
            console.log(error);
        },
    });
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsPickerOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCategoryNameChange = (categoryName: string) => {
        setCurrentName((prevState) => ({
            ...prevState,
            categoryName: categoryName,
            subCategoryName: null,
        }));
    };

    const handleSubCategoryNameChange = (categoryName: string, subCategoryName: string) => {
        setCurrentName((prevState) => ({
            ...prevState,
            categoryName: null,
            subCategoryName: subCategoryName,
        }));
    };

    const handleCategoryIdChange = (categoryId: number) => {
        setCurrentId({
            ...currentId,
            categoryId: categoryId,
            subCategoryId: null,
        });
    };

    const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
        setCurrentId({
            ...currentId,
            categoryId: subCategoryId
        });
    };
    return (
        <>
            <Col sm={4} md={3} lg={4} xxl={3} className='position-relative w-100'>
                <div>
                    < i className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2'
                        style={{zIndex: 1}} onClick={() => setIsPickerOpen(!isPickerOpen)}></i>
                    <Form.Group>
                        <Form.Control
                            type='text'
                            readOnly
                            placeholder={t('Select Category')}
                            id='categoryInput-field'
                            value={
                                currentName.subCategoryName
                                    ? currentName.subCategoryName
                                    : currentName.categoryName
                                        ? currentName.categoryName
                                        : ''
                            }
                            onClick={() => setIsPickerOpen(!isPickerOpen)}
                        />
                    </Form.Group>
                </div>
                <div
                    ref={dropdownRef}
                    className='dropdown position-absolute opacity-1 z-3 top-10 start-0 end-0 mt-2 bg-white shadow'
                >
                    {categoryList && (
                        <CategoryPickerComponent
                            isOpen={isPickerOpen}
                            locale={i18n.language}
                            onSearchChange={(searchValue: string) => { }}
                            onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                                if (currentCategory.isPicked) {
                                    handleCategoryNameChange(currentCategory?.name);
                                    handleCategoryIdChange(currentCategory?.id);
                                    setIsPickerOpen(false);
                                } else {
                                    setCurrentName({ categoryName: '', subCategoryName: '' });
                                    setCurrentId({
                                        categoryId: null,
                                        subCategoryId: null,
                                    })
                                }
                            }}
                            onChangeSubcategory={(currentSubcategory, currentCategory) => {
                                if (currentSubcategory.isPicked) {
                                    handleSubCategoryNameChange("", currentSubcategory?.name);
                                    handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id);
                                    setIsPickerOpen(false);
                                } else {
                                    setCurrentName({ categoryName: '', subCategoryName: '' });
                                    setCurrentId({
                                        categoryId: null,
                                        subCategoryId: null,
                                    })
                                }
                            }}
                            serviceCategories={categoryList}
                        />
                    )}
                </div>
            </Col>
        </>
    );
};

export default CategoryInput;
