import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CategoryPickerCategory, CategoryPickerComponent } from '@me-pos/category-picker';
import { ApolloError } from '@apollo/client';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import CategoryService, { CategoryInterface } from '../../../../services/serviceCategory/ServicesCategoryNewInnit';
import {
    useGetCategoryServicesNewQuery,
    useGetServicesQuery
} from '@me-team/host/main/modalSalary/graphql/modalSalary.hooks';
import i18n from "@me-team/host/src/i18n";

interface CategoryInputProps {
    valueCategory?: number;
    valueSubCategory?: number;
    nameCategory?: string;
    nameSubCategory?: string;
    updateCategoryId: (categoryId: number) => void;
    updateSubCategoryId: (subCategoryId: number) => void;
    updateCategoryName: (categoryName: string) => void;
    updateSubCategoryName: (subCategoryName: string) => void;
    t: (key: string) => string;
    isValid?: boolean
    error?: boolean
}

const CategoryInput: React.FC<CategoryInputProps> = ({
    valueCategory,
    valueSubCategory,
    nameCategory,
    nameSubCategory,
    updateCategoryId,
    updateSubCategoryId,
    updateCategoryName,
    updateSubCategoryName,
    t,
    isValid,
}) => {
    const dropdownRef = useRef(null);

    const [categoryList, setCategoryList] = useState<CategoryInterface[]>([]);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const startValueCategory = valueCategory ? valueCategory : null;
    const startValueSubCategory = valueSubCategory ? valueSubCategory : null;
    const [currentId, setCurrentId] = useState({
        categoryId: startValueCategory,
        subCategoryId: startValueSubCategory,
    });
    const [currentName, setCurrentName] = useState({
        categoryName: nameCategory || '',
        subCategoryName: nameSubCategory || '',
    });
    const { data: categoryData, loading, error } = useGetServicesQuery({
        onCompleted: (data) => {
            data?.serviceCategories?.items &&
            setCategoryList(
                CategoryService.categoryModuleCreator(
                    data?.serviceCategories?.items,
                    valueCategory ? valueCategory : null,
                    valueSubCategory ? valueSubCategory : null
                )
            );
        },
        onError: (error: ApolloError) => {
            console.log(error);
        },
    });
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsPickerOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCategoryNameChange = (categoryName: string) => {
        setCurrentName((prevState) => ({
            ...prevState,
            categoryName: categoryName,
            subCategoryName: "",
        }));
        updateCategoryName(categoryName);
    };

    const handleSubCategoryNameChange = (categoryName: string, subCategoryName: string) => {
        setCurrentName((prevState) => ({
            ...prevState,
            categoryName: categoryName,
            subCategoryName: subCategoryName,
        }));
        updateCategoryName(categoryName);
        updateSubCategoryName(subCategoryName);
    };

    const handleCategoryIdChange = (categoryId: number) => {
        setCurrentId({
            ...currentId,
            categoryId: categoryId,
            subCategoryId: null,
        });
        updateCategoryId(categoryId);
        updateSubCategoryId(null);
    };

    const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
        setCurrentId({
            ...currentId,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
        });
        updateCategoryId(categoryId);
        updateSubCategoryId(subCategoryId);
    };
    const clearSelection = () => {
        setCurrentName({ categoryName: '', subCategoryName: '' });
        setCurrentId({ categoryId: '', subCategoryId: '' });
        updateCategoryId(null);
        updateSubCategoryId(null);
    };
    // console.log(currentId)
    if (loading) return <Spinner />;
    return (
        <>
            <Col sm={4} md={3} lg={4} xxl={3} className='position-relative w-100'>
                {!!isValid ||
                    (< i
                        className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2'
                        style={{zIndex: 4}} onClick={() => setIsPickerOpen(!isPickerOpen)}>

                    </i>)}
                <div>
                    <Form.Group>
                        <Form.Control
                            type='text'
                            readOnly

                            placeholder={t('Select a category/subcategory')}
                            value={
                                currentName.subCategoryName
                                    ? currentName.subCategoryName
                                    : currentName.categoryName
                                        ? currentName.categoryName
                                        : ''
                            }
                            onClick={() => setIsPickerOpen(!isPickerOpen)}
                            isInvalid={!!isValid}
                            id="name"
                        />
                    </Form.Group>
                </div>
                <div
                    ref={dropdownRef}
                    className={`dropdown position-absolute opacity-1 z-3 top-10 start-0 end-0 mt-2 bg-white shadow ${isPickerOpen ? 'show' : ''}`}
                >
                    {categoryList && (
                        <CategoryPickerComponent
                            isOpen={isPickerOpen}
                            locale={i18n.language}
                            onSearchChange={(searchValue: string) => { }}
                            onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                                if (currentCategory.isPicked) {
                                    handleCategoryNameChange(currentCategory.name);
                                    handleCategoryIdChange(currentCategory.id);
                                    setIsPickerOpen(false);
                                } else {
                                    clearSelection()
                                }
                            }}
                            onChangeSubcategory={(currentSubcategory, currentCategory) => {
                                if (currentSubcategory.isPicked) {
                                    handleSubCategoryNameChange(currentCategory.name, currentSubcategory.name);
                                    handleSubCategoryIdChange(currentCategory.id, currentSubcategory.id);
                                    setIsPickerOpen(false);
                                } else {
                                    clearSelection()
                                }
                            }}
                            serviceCategories={categoryList}
                        />
                    )}
                </div>
            </Col>
        </>
    );
};

export default CategoryInput;


