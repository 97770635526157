import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {  useParams } from 'react-router-dom';
import {OverlayTrigger, Tooltip, Table, Form, Col, Container, Row} from 'react-bootstrap';
import { Formik, Form as FormFormik } from 'formik';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import { Pagination } from '@services/ui-components';
import DurationInput from '../../../components/ui-components/DurationInput/DurationInput';
import PriceComponent from "../../ui-components/PriceComponent/PriceComponent";
import EmployeeCell from '@services/ui-components/src/EmployeeCell/EmployeeCell';
import SalaryComponent from '../../ui-components/SalaryComponent/SalaryComponent';
import ServicesModalConfirmation from '../../ui-components/ServicesModalConfirmation/ServicesModalConfirmation';
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../hooks/useToast";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar, currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import {debounce} from "@me-team/host/src/utils/utils";
import SocketConnection from "../../../services/SocketConnection";
import { useGetServicesProvidersQuery } from '@me-team/host/main/servicesProviders/graphql/servicesProviders.hooks';
import { useGetCurrencySignQuery } from '@me-team/host/main/appointment/graphql/appointment.hooks';
import {
    useCreateAllRelationsMutation,
    useCreateEmployeeServiceRelationMutation, useDeleteAllRelationsMutation, useDeleteEmployeeServiceRelationMutation,
    useUpdateEmployeeServiceRelationMutation
} from '@me-team/host/main/employeeServices/graphql/employeeServices.hooks';
import { EmployeeServiceRelationProxy, SalaryType, ServiceProxy } from '@me-team/host/main/graphql/types';
import ExistingBookingsModal from "../../EmployeeServices/EmployeeServicesTable/ExistingBookingsModal";
import JoyrideWrapper from "../../Joyride/JoyrideWrapper";

interface Filter {
    positionId?: number
    name?: string
}
interface ServiceProvidersTableProps {
    filter: Filter;
    setServiceName: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const ServiceProvidersTable: React.FC<ServiceProvidersTableProps> = ({ filter, setServiceName }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const ENTITY_IMPORT_TYPE: string = 'service-providers';
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [show, setShow] = useState(false)
    const [text, setText] = useState(" ");
    const [isLoadingRefetchServices, setIsLoadingRefetchServices] = useState<boolean>(false);
    const [showExistingBooking, setShowExistingBooking] = useState(false);
    const runJoy = localStorage.getItem('run') || false;
    const step = localStorage.getItem('step') || '0';
    const [stepIndex, setStepIndex] = useState(+step);
    const [run, setRun] = useState(runJoy);
    const userRole = useReactiveVar(currentUserRoleVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const { showToast, toggleShowToast, setShowToast } = useToast();

    const { data, loading, refetch } = useGetServicesProvidersQuery({
        variables: { serviceId: +id, page: currentPage, itemsPerPage: itemsPerPage, employeePosition: filter.positionId, employeeName: filter.name },
        onCompleted: (data) => {
            setServiceName(data?.user.company.services.services[0].name)
        }

    })
    const debouncedRefetch = useCallback(
        debounce(() => {
            setIsLoadingRefetchServices(true);
            refetch().then((data) => {
                setIsLoadingRefetchServices(false);
            });
        }, 1000),
        [refetch]
    );

    useEffect(() => {
        if (currentCompanyId) {
            new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
                if (data.entity === ENTITY_IMPORT_TYPE) {
                    debouncedRefetch()
                }
            })
        }
    }, [currentCompanyId, refetch]);
    const selectAllData = data?.user?.company?.employeesList

    useDocumentTitle(`${t('Service Providers')} «${data?.user.company.services.services[0].name || ' '} »`)
    const { data: currency, loading: currencyLoading } = useGetCurrencySignQuery()
    const currencySign = currency?.user?.company?.currency?.currencySign
    const servicesProviders = data?.user?.company?.services?.services[0]?.serviceEmployees
    const duration = data?.user?.company?.services?.services[0]?.duration?.slice(0, -3);

    const pageCount = servicesProviders?.pageCount
    const totalItems = servicesProviders?.totalCount || 0
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const [updateServices] = useUpdateEmployeeServiceRelationMutation({
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data)

        },
        onError: (error) => {
            console.error('Error occurred during mutation:', error)
        },
        context: {
            errorType: 'local',
        },
    })
    const [createRelation] = useCreateEmployeeServiceRelationMutation({
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data)

        },
        onError: (error) => {
            console.error('Error occurred during mutation:', error)
        },
        context: {
            errorType: 'local',
        },
    })
    const [deleteRelation] = useDeleteEmployeeServiceRelationMutation({
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data)
        },
        onError: (error) => {
            console.error('Error occurred during mutation:', error)
        },
        context: {
            errorType: 'local',
        },
    })
    const handleToggleModal = () => setShow(prevState => !prevState);
    const [createAllRelations] = useCreateAllRelationsMutation()
    const [deleteAllRelation] = useDeleteAllRelationsMutation()
    const changeRelationForAll = async () => {
        try {
            const operation = checkEmployeesList ? deleteAllRelation : createAllRelations;
            const resp = await operation({
                variables: {
                    input: {
                        "service": +id
                    }
                }
            });
            if (resp) {
                toggleShowToast()
                refetch()
            }
        } catch (error) {
            console.error('Create relation error', error);
        }
    };
    const updateEmployeeServices = async (values: EmployeeServiceRelationProxy) => {
        const variables = {
            "duration": values.duration,
            "price": values.price,
            "onlineBooking": values.online,
            "salaryType": values.salaryType === 1 ? SalaryType.Percent : SalaryType.Currency,
            "salaryValue": values.salaryValue ?? data?.user.company.services.services[0].price
        }

        try {
            const resp = await updateServices({ variables: { "relationId": values.id, input: variables } })
            toggleShowToast()
            refetch()
        } catch (error) {
            console.error('Update error', error)
        }
    }
    const deleteServiceRelation = async (values: EmployeeServiceRelationProxy) => {
        const resp = await deleteRelation({ variables: { id: values.employeeServiceRelations[0].id } })
        if (resp?.errors?.message === "Relation has bookings in future and cannot be deleted") {
            setShowExistingBooking(true)
        } else {
            toggleShowToast()
            refetch()
        }
    }
    const createServiceRelation = async (values: EmployeeServiceRelationProxy) => {
        const variables = {
            "employeeId": values.id,
            "serviceId": +id,
            "duration": data?.user.company.services.services[0].duration,
            "price": data?.user.company.services.services[0].price,
            "onlineBooking": false,
        }
        try {
            const resp = await createRelation({ variables })
            toggleShowToast()
            refetch()
        } catch (error) {
            console.error('Update error', error)
        }

    }
    const handleUpdateRelation = async (values: EmployeeServiceRelationProxy) => {
        values.employeeServiceRelations.length > 0 && !values?.employeeServiceRelations[0]?.deleted ? deleteServiceRelation(values) : createServiceRelation(values)
    }
    const updateOnlineBooking = (i: ServiceProxy) => {
        setSelectedEmployee(i)
        const service = data?.user?.company?.services?.services[0].name
        const employee  = i
        const onlineRegistration = data?.user.company.services.services[0]?.onlineRegistration
        const isOnlineSignUpAvailable = i?.recordSetting?.isOnlineSignUpAvailable
        switch (true) {
            case isOnlineSignUpAvailable === false && onlineRegistration !== false:
                setText(`${employee?.name} ${employee?.surname} ${t('is not available for Online Registration form. Should we make')} ${employee?.name} ${employee?.surname} ${t('available in the Online Registration form for your clients?')}`)
                break;
            case isOnlineSignUpAvailable === true && onlineRegistration === false:
                setText(`${service} ${t('is not available for Online Registration form. Should we make')} ${service} ${t('available in the Online Registration form for your clients?')}`)
                break;
            case isOnlineSignUpAvailable === false && onlineRegistration === false:
                setText(` ${t('Service')} ${service} ${t('and')} ${employee?.name} ${employee?.surname} ${t('are not available for Online Booking form. Should we make')} ${service} ${t('and')} ${employee?.name} ${employee?.surname} ${t('available in the Online Booking form for your clients')} ?`)
                break;
            default:
                updateEmployeeServices({ ...i.employeeServiceRelations[0], online: !i.employeeServiceRelations[0].online})
                return
        }
        !i.employeeServiceRelations[0].online
            ? handleToggleModal()
            : updateEmployeeServices({
                ...i.employeeServiceRelations[0],
                online: !i.employeeServiceRelations[0].online,
                salaryType: i.employeeServiceRelations[0].salaryType === 1 ? SalaryType.Percent : SalaryType.Currency
            })
    }
    const updateDuration = (i: ServiceProxy, duration) => {
        updateEmployeeServices({ ...i.employeeServiceRelations[0], duration: duration })
    }

    function convertTimeFormat(timeStr: string) {
        const parts = timeStr.split(':');
        if (parts.length === 3) {
            return `${parts[0]}:${parts[1]}`;
        } else {
            return timeStr;
        }
    }

    const checkEmployeesList = useMemo(() => {
        return selectAllData?.every(service => service.employeeServiceRelations.length > 0) || false;
    }, [selectAllData]);

    if (loading || currencyLoading) return <Spinner />

    return (
        <>
            <JoyrideWrapper run={run} stepIndex={stepIndex} setStepIndex={setStepIndex} booleanValue={servicesProviders?.serviceEmployees[0].employeeServiceRelations.length > 0} />
            {servicesProviders?.serviceEmployees?.length === 0 ?
                <Container className="mt-5">
                    <Row className="mt-5">
                        <Col xs={12}>
                            <h4 className="head-personal-data text-center fw-normal pt-5 mt-5">
                                {t("NoDataFoundForTheGivenValues")}
                            </h4>
                        </Col>
                    </Row>
                </Container>
                :
                <>
                    <Table responsive >
                        <thead>
                        <tr>
                            <th>
                                <OverlayTrigger
                                    overlay={<Tooltip>{t('Select the providers of this service')}</Tooltip>}>
                                    <Form>
                                        <Form.Check
                                            type={"checkbox"}
                                            id={`serviceProviders-selectAll-field`}
                                            label={<i className="bi bi-info-circle"> </i>}
                                            checked={checkEmployeesList}
                                            onChange={() => changeRelationForAll()}
                                            disabled={userRole === 5}
                                        />
                                    </Form>
                                </OverlayTrigger>
                            </th>
                            <th>{t('Online Booking')}</th>
                            <th>{t('Employee')}</th>
                            <th>{t('Duration')}</th>
                            <th>{t('Cost')}</th>
                            <th>{t('Salary')}</th>
                        </tr>
                        </thead>
                        {servicesProviders?.serviceEmployees?.length === 0 ?
                            <Container className="mt-5">
                                <Row className="mt-5">
                                    <Col xs={12}>
                                        <h4 className="head-personal-data text-center fw-normal pt-5 mt-5">
                                            {t("NoDataFoundForTheGivenValues")}
                                        </h4>
                                    </Col>
                                </Row>
                            </Container>
                            :
                            <tbody>

                            {servicesProviders?.serviceEmployees.map((i, index) => (
                                <tr key={i?.name + index}>
                                    <td className={index === 0 ? 'service-providers' : ''}>
                                        <Form>
                                            <Form.Check
                                                type={"checkbox"}
                                                id={`serviceProviders-${"updateRelation" + index}-field`}
                                                checked={
                                                    i.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted
                                                }
                                                onChange={() => handleUpdateRelation(i)}
                                                disabled={userRole === 5}
                                            />
                                        </Form>
                                    </td>
                                    <td>
                                        <Form>
                                            <Form.Check
                                                disabled={userRole === 5 || i.employeeServiceRelations.length === 0}
                                                type="switch"
                                                id={`serviceProviders-${"updateOnlineBooking" + index}-field`}
                                                checked={i?.employeeServiceRelations[0]?.online}
                                                onChange={() => updateOnlineBooking(i)}
                                            />
                                        </Form>
                                    </td>
                                    <td>
                                        <EmployeeCell i={i} link={userRole !== 5}/>
                                    </td>
                                    <td>
                                        <div style={{ width: '94px' }}>
                                            {
                                                i?.employeeServiceRelations[0]?.duration && !i?.employeeServiceRelations[0]?.deleted ?
                                                    <Formik
                                                        initialValues={{}}
                                                        onSubmit={values => {

                                                        }}
                                                    >
                                                        {formik => (
                                                            <FormFormik onSubmit={formik.handleSubmit}>
                                                                <DurationInput
                                                                    name="Name"
                                                                    label="label name"
                                                                    prevValue={
                                                                        i?.employeeServiceRelations[0]?.duration || i?.duration
                                                                    }
                                                                    serviceValue={i}
                                                                    updateDuration={updateDuration}
                                                                    isDisabled={userRole === 5}
                                                                />
                                                            </FormFormik>
                                                        )}
                                                    </Formik> : duration
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        {i?.employeeServiceRelations.length > 0 && !i?.employeeServiceRelations[0]?.deleted ? <PriceComponent service={i} updateEmployeeServices={updateEmployeeServices} currencySign={currencySign} index={index} /> : data?.user.company.services.services[0].price + ' ' + currencySign}
                                    </td>
                                    <td>
                                        {i?.employeeServiceRelations?.length > 0 && !i?.employeeServiceRelations[0]?.deleted ? (
                                            <SalaryComponent
                                                service={i?.employeeServiceRelations[0]}
                                                updateEmployeeServices={updateEmployeeServices}
                                                currencySign={currencySign}
                                                index={index}
                                                value={i?.employeeServiceRelations[0]?.salaryValue}
                                            />
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        }

                    </Table >

                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <Pagination
                            t={t}
                            pages={Number(pageCount)}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            firstItemIndex={firstItemIndex}
                            lastItemIndex={lastItemIndex}
                            totalItems={totalItems}
                        />
                    </div>
                </>
            }

            <ServicesModalConfirmation
                show={show}
                handleToggleModal={handleToggleModal}
                selectedEmployee={selectedEmployee}
                updateFunc={updateEmployeeServices}
                text={text}
            />

            <ToastComponent show={showToast} setShow={setShowToast} text={t('Saved')}/>
            <ExistingBookingsModal show={showExistingBooking} setShow={setShowExistingBooking}   />
        </>
    );
};

export default ServiceProvidersTable;
