import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

type SearchComponentProps = {
    searchHandling: (currentValue: string) => void
}

export const SearchComponent: React.FC<SearchComponentProps> = (props) => {

    const [searchValue, setSearchValue] = useState<string>('')
    const {t: translate} = useTranslation();
    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const applySearchHandler = () => {
        props.searchHandling(searchValue.toLowerCase())
    }

    const onClearHandler = () => {
        setSearchValue('')
        props.searchHandling('')
    }

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            applySearchHandler();
        }
    };

    return (
        <Row className='gx-3'>
            <Col lg={9}>
                <input value={searchValue} type="text" className="form-control"
                       placeholder={translate("search")}
                       onChange={onSearch}
                       onKeyDown={handleSearchKeyDown}
                />
            </Col>
            <Col lg={3}>
                <Row className='gx-3 h-100'>
                    <Col lg={6}>
                        <button className="btn btn-outline-dark border-grey w-100 h-100 fw-normal"
                                onClick={onClearHandler}
                        >{translate("clear")}</button>
                    </Col>
                    <Col lg={6}>
                        <Button variant={'primary'} className="w-100 h-100 fw-normal"
                                onClick={applySearchHandler}
                        >{translate("apply")}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}