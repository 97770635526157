import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import StartStep from "./StartStep";
import {useState} from "react";

const AddServiceStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep} />
            ) : (
                <Row>
                    <Col xs={11}>
                        <p className='fs-7'>
                            <span className="fs-normal fw-bold fs-7">Заповніть обов’язкові поля </span>
                            <span className="text-danger">*</span>
                        </p>
                        <p className='fs-7'>
                            <span className="fs-normal fw-bold fs-7">Ввімкніть перемикач, щоб інформація відображалась у формі онлайн-запису</span>
                        </p>
                        <span className="fs-normal fw-bold fs-7">Натисніть “Зберегти” для завершення</span>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                    </Col>
                </Row>
            )}
        </div>


    )
}

export default AddServiceStep;
