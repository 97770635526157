
class RequestInputService {
  public static createServiceCategoryInput = (categoryId: number | null, subcategoryId: number | null, categoryList: any[]): any => {

    if (categoryId && !subcategoryId) {
      const currentCategory = categoryList.find((categoryItem) => categoryItem.id === categoryId);

      if (currentCategory) {
        return {
          comment: currentCategory.comment,
          parentCategoryId: null,
          name: currentCategory.name,
          deleted: currentCategory.deleted
        }

      } else {
        return {
          comment: null,
          parentCategoryId: null,
          name: '',
          deleted: false
        }
      }

    }

    if (categoryId && subcategoryId) {
      const currentCategory: any = categoryList.find((categoryItem) => categoryItem.id === categoryId);
      if (currentCategory) {
        const currentSubcategory: any =
          currentCategory.childCategories.find((subcategoryItem: any) => subcategoryItem.id === subcategoryId);
        if (currentSubcategory) {
          return {
            comment: currentSubcategory.comment,
            parentCategoryId: currentSubcategory.parentCategory.id,
            name: currentSubcategory.name,
            deleted: currentSubcategory.deleted
          }
        } else {
          return {
            comment: null,
            parentCategoryId: categoryId,
            name: '',
            deleted: false
          }
        }
      }
    }

    return {
      comment: null,
      parentCategoryId: categoryId,
      name: '',
      deleted: false
    }
  }

  public static createCategoryInputFromFormData = (currentCategoryInput: any,
                                                   formData: { categoryName: string; description: string },
                                                   isCreate : boolean, categoryId : number) : any => {
    if (isCreate) {
      return {
        name: formData.categoryName,
        comment: formData.description.trim() ? formData.description : null,
        deleted: currentCategoryInput.deleted,
        parentCategoryId: currentCategoryInput.parentCategoryId ? currentCategoryInput.parentCategoryId : categoryId
      };
    } else {
      if (currentCategoryInput.deleted) {
        return {
          name: formData.categoryName,
          comment: formData.description.trim() ? formData.description : null,
          deleted: false,
          parentCategoryId: currentCategoryInput.parentCategoryId
        };
      } else {
        return {
          name: formData.categoryName,
          comment: formData.description.trim() ? formData.description : null,
          deleted: currentCategoryInput.deleted,
          parentCategoryId: currentCategoryInput.parentCategoryId
        };
      }
    }

  }

}

export default RequestInputService