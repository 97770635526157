import * as Yup from 'yup';
import {TFunction} from 'i18next';
import {FormData} from './CreateServiceForm';
import {ServiceInput} from '@me-team/host/main/graphql/types';

export type SetFieldValueType = (field: string, value: string | boolean | string[] | File | (string | File)[]) => void;

const noHtmlAndLinks=(t: TFunction) => Yup.string()
  .test('no-html-tags', t('validation.HTML tags are not allowed'), value => !/<\/?[^>]+(>|$)/g.test(value))
  .test('no-links', t('validation.links are not allowed'), value => !/https?:\/\/[^\s]+/g.test(value));

export const createServiceFormValidationSchema=(t: TFunction) => Yup.object().shape({
  name: Yup.string().trim().required(t('Fill this field')),
  category: Yup.number().required(t('Fill this field')),
  description: noHtmlAndLinks(t),
  duration: Yup.string().required(t('Fill this field')),
  price: Yup.number().required(t('Fill this field')).min(0.01, t('Price must be greater than 0')),
  onlineBooking: Yup.boolean(),
});

// Generic function for creating a basic ServiceInput object
const createServiceInput = (
  company: number,
  name: string,
  categoryId: number,
  duration: string,
  price: number,
  onlineBooking: boolean,
  deleted: boolean,
  isUpdateImage: boolean = false,
  description?: string,
  image?: File | null,
): ServiceInput => {
  return {
    company: company,
    name,
    category: categoryId,
    duration,
    price,
    onlineBooking,
    deleted: false,
    isUpdateImage,
    description,
    image,
  };
};

export const getServiceInputFor = (
  actionType: 'create' | 'duplicate' | 'restoreDuplicate',
  companyId: number,
  values?: FormData,
  serviceDuplicate?: any
  ) => {
  switch (actionType) {
    case 'duplicate':
    case 'restoreDuplicate': {
      const categoryId = serviceDuplicate?.serviceCategoryNew?.id;
      return createServiceInput(
        companyId,
        serviceDuplicate?.name,
        categoryId,
        serviceDuplicate?.duration,
        serviceDuplicate?.price ? serviceDuplicate?.price : null,
        serviceDuplicate?.onlineRegistration,
        false,
        actionType === 'restoreDuplicate',
        serviceDuplicate?.description,
        serviceDuplicate?.image ? serviceDuplicate?.image : null,
      );
    }

    case 'create': {
      return createServiceInput(
        companyId,
        values?.name,
        values?.category,
        values?.duration,
        values?.price,
        values?.onlineBooking,
        false,
        false,
        values?.description,
        values?.image,
      )
    }

    default:
      throw new Error('Invalid action type');
  }
};

export const normalizeCategoryData = (data: any) => {
  return data?.productCategories?.items || data?.serviceCategories?.items || [];
};


