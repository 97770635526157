import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";

const WorkScheduleModalStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <Row>
                    <Col xs={11}>
                        <span className="fs-normal fw-bold fs-7">Перейдіть до “Cтворення графіку роботи”</span>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg "></i>
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
}


export default WorkScheduleModalStep;  