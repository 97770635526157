/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetServicesCategoryDocument = gql`
    query GetServicesCategory {
  serviceCategories {
    items {
      id
      name
      childCategories {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetServicesCategoryQuery__
 *
 * To run a query within a React component, call `useGetServicesCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServicesCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>(GetServicesCategoryDocument, options);
      }
export function useGetServicesCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>(GetServicesCategoryDocument, options);
        }
export function useGetServicesCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>(GetServicesCategoryDocument, options);
        }
export type GetServicesCategoryQueryHookResult = ReturnType<typeof useGetServicesCategoryQuery>;
export type GetServicesCategoryLazyQueryHookResult = ReturnType<typeof useGetServicesCategoryLazyQuery>;
export type GetServicesCategorySuspenseQueryHookResult = ReturnType<typeof useGetServicesCategorySuspenseQuery>;
export type GetServicesCategoryQueryResult = Apollo.QueryResult<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>;
export const GetServicesDocument = gql`
    query getServices($categoryName: String = null, $itemsPerPage: Int = 1000, $serviceDeleted: Boolean = false, $page: Int = 1) {
  serviceCategories(
    categoryName: $categoryName
    itemsPerPage: $itemsPerPage
    page: $page
  ) {
    itemsPerPage
    currentPage
    pageCount
    totalCount
    items {
      id
      name
      comment
      deleted
      parentCategory {
        id
        name
        deleted
      }
      company {
        id
        name
        address
        phone
        website
        deleted
        services {
          pageCount
        }
      }
      services(deleted: $serviceDeleted) {
        id
        name
        onlineRegistration
        deleted
      }
      childCategories(name: $categoryName) {
        id
        name
        comment
        deleted
        services(deleted: $serviceDeleted) {
          id
          name
          onlineRegistration
          deleted
        }
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *      categoryName: // value for 'categoryName'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      serviceDeleted: // value for 'serviceDeleted'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
      }
export function useGetServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
        }
export function useGetServicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
        }
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<typeof useGetServicesLazyQuery>;
export type GetServicesSuspenseQueryHookResult = ReturnType<typeof useGetServicesSuspenseQuery>;
export type GetServicesQueryResult = Apollo.QueryResult<GetServicesQuery, GetServicesQueryVariables>;
export const GetCategoryServicesNewDocument = gql`
    query GetCategoryServicesNew {
  user {
    id
    company {
      id
      name
      deleted
      serviceCategoriesNew(id: null, name: null, deleted: false) {
        id
        name
        childCategories(id: null, name: null) {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryServicesNewQuery__
 *
 * To run a query within a React component, call `useGetCategoryServicesNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryServicesNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryServicesNewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryServicesNewQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>(GetCategoryServicesNewDocument, options);
      }
export function useGetCategoryServicesNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>(GetCategoryServicesNewDocument, options);
        }
export function useGetCategoryServicesNewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>(GetCategoryServicesNewDocument, options);
        }
export type GetCategoryServicesNewQueryHookResult = ReturnType<typeof useGetCategoryServicesNewQuery>;
export type GetCategoryServicesNewLazyQueryHookResult = ReturnType<typeof useGetCategoryServicesNewLazyQuery>;
export type GetCategoryServicesNewSuspenseQueryHookResult = ReturnType<typeof useGetCategoryServicesNewSuspenseQuery>;
export type GetCategoryServicesNewQueryResult = Apollo.QueryResult<GetCategoryServicesNewQuery, GetCategoryServicesNewQueryVariables>;
export const GetCurrencySignDocument = gql`
    query GetCurrencySign {
  user {
    id
    company {
      id
      name
      currency {
        code
        currency
        currencySign
      }
    }
  }
}
    `;

/**
 * __useGetCurrencySignQuery__
 *
 * To run a query within a React component, call `useGetCurrencySignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencySignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencySignQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencySignQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrencySignQuery, GetCurrencySignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencySignQuery, GetCurrencySignQueryVariables>(GetCurrencySignDocument, options);
      }
export function useGetCurrencySignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencySignQuery, GetCurrencySignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencySignQuery, GetCurrencySignQueryVariables>(GetCurrencySignDocument, options);
        }
export function useGetCurrencySignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrencySignQuery, GetCurrencySignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrencySignQuery, GetCurrencySignQueryVariables>(GetCurrencySignDocument, options);
        }
export type GetCurrencySignQueryHookResult = ReturnType<typeof useGetCurrencySignQuery>;
export type GetCurrencySignLazyQueryHookResult = ReturnType<typeof useGetCurrencySignLazyQuery>;
export type GetCurrencySignSuspenseQueryHookResult = ReturnType<typeof useGetCurrencySignSuspenseQuery>;
export type GetCurrencySignQueryResult = Apollo.QueryResult<GetCurrencySignQuery, GetCurrencySignQueryVariables>;
export const CreateServiceCategoryDocument = gql`
    mutation CreateServiceCategory($employeeId: Int!, $input: ServiceCategorySalaryInput!) {
  createServiceCategorySalary(employeeId: $employeeId, input: $input) {
    id
    selectedAll
  }
}
    `;
export type CreateServiceCategoryMutationFn = Apollo.MutationFunction<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>;

/**
 * __useCreateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceCategoryMutation, { data, loading, error }] = useCreateServiceCategoryMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>(CreateServiceCategoryDocument, options);
      }
export type CreateServiceCategoryMutationHookResult = ReturnType<typeof useCreateServiceCategoryMutation>;
export type CreateServiceCategoryMutationResult = Apollo.MutationResult<CreateServiceCategoryMutation>;
export type CreateServiceCategoryMutationOptions = Apollo.BaseMutationOptions<CreateServiceCategoryMutation, CreateServiceCategoryMutationVariables>;
export const UpdateServiceCategoryModalDocument = gql`
    mutation UpdateServiceCategoryModal($serviceCategorySalaryId: Int!, $input: ServiceCategorySalaryInput!) {
  updateServiceCategorySalary(
    serviceCategorySalaryId: $serviceCategorySalaryId
    input: $input
  ) {
    id
    selectedAll
  }
}
    `;
export type UpdateServiceCategoryModalMutationFn = Apollo.MutationFunction<UpdateServiceCategoryModalMutation, UpdateServiceCategoryModalMutationVariables>;

/**
 * __useUpdateServiceCategoryModalMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCategoryModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCategoryModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCategoryModalMutation, { data, loading, error }] = useUpdateServiceCategoryModalMutation({
 *   variables: {
 *      serviceCategorySalaryId: // value for 'serviceCategorySalaryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceCategoryModalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceCategoryModalMutation, UpdateServiceCategoryModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceCategoryModalMutation, UpdateServiceCategoryModalMutationVariables>(UpdateServiceCategoryModalDocument, options);
      }
export type UpdateServiceCategoryModalMutationHookResult = ReturnType<typeof useUpdateServiceCategoryModalMutation>;
export type UpdateServiceCategoryModalMutationResult = Apollo.MutationResult<UpdateServiceCategoryModalMutation>;
export type UpdateServiceCategoryModalMutationOptions = Apollo.BaseMutationOptions<UpdateServiceCategoryModalMutation, UpdateServiceCategoryModalMutationVariables>;
export type GetServicesCategoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetServicesCategoryQuery = { __typename?: 'Query', serviceCategories?: { __typename?: 'ServiceCategoryNewPaginated', items?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string } | null> | null }> | null } | null };

export type GetServicesQueryVariables = Types.Exact<{
  categoryName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetServicesQuery = { __typename?: 'Query', serviceCategories?: { __typename?: 'ServiceCategoryNewPaginated', itemsPerPage?: number | null, currentPage?: number | null, pageCount?: string | null, totalCount?: number | null, items?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null } | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, onlineRegistration: boolean, deleted: boolean }> | null, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, onlineRegistration: boolean, deleted: boolean }> | null, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type GetCategoryServicesNewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCategoryServicesNewQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, name: string, deleted: boolean, serviceCategoriesNew?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null> | null }> | null } | null } | null };

export type GetCurrencySignQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrencySignQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, name: string, currency?: { __typename?: 'CurrencyProxy', code?: string | null, currency?: string | null, currencySign?: string | null } | null } | null } | null };

export type CreateServiceCategoryMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  input: Types.ServiceCategorySalaryInput;
}>;


export type CreateServiceCategoryMutation = { __typename?: 'Mutation', createServiceCategorySalary: { __typename?: 'ServiceCategorySalaryProxy', id: number, selectedAll: boolean } };

export type UpdateServiceCategoryModalMutationVariables = Types.Exact<{
  serviceCategorySalaryId: Types.Scalars['Int']['input'];
  input: Types.ServiceCategorySalaryInput;
}>;


export type UpdateServiceCategoryModalMutation = { __typename?: 'Mutation', updateServiceCategorySalary: { __typename?: 'ServiceCategorySalaryProxy', id: number, selectedAll: boolean } };
