import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";

const OnlineCallStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={1} className="p-0 text-center">

                            <i className="bi bi-info-circle"></i>

                        </Col>
                        <Col xs={10}>
                            <p className='fs-7 text-dark'>"Дзвінок для підтвердження" - це налаштування визначає чи може клієнт
                                обрати "не передзванювати" під час створення онлайн-запису.</p>
                            <p className='fs-7'>Якщо воно увімкнено, всі записи, створені клієнтами, будуть зі статусом
                                "Підтвердження клієнта".</p>
                            <p className='fs-7'>Якщо воно вимкнено і клієнт обрав "не перездзванювати", запис автоматично
                                створиться у статусі "Очікується клієнт".</p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-0 pt-3  border-top'>
                        <Col className='text-end p-0'>
                            <Button className="px-4" onClick={() => goToStep('next')}>Далі</Button>
                        </Col>

                    </Row>
                </>
            )}

        </>
    )
};

export default OnlineCallStep;
