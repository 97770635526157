import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { beautySalonFormValidationSchema } from './constants';
import { useNavigate } from 'react-router-dom';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import PhonePicker from '@services/ui-components/src/PhonePicker/PhonePicker';
import { ReactSelect, Spinner, ReactAsyncSelect, SpinnerLoader } from '@services/ui-components';
import { useReactiveVar } from '@apollo/client';
import { currentUserEmailVar } from '@me-team/host/src/apollo/globalVar/state';
import DropPhotosMultiply from '../../../DropPhotosMultiply/DropPhotosMultiply';
import { useGetCitiesQuery, useGetCountriesQuery } from '@me-team/host/main/branches/graphql/branches.hooks';
import {
    useCreateCompanyBeautySalonMutation,
    useGetCurrenciesQuery,
    useGetGmtQuery,
    useGetLanguagesQuery
} from '@me-team/host/main/company/graphql/company.hooks';

const CreateCompanyForm: React.FC = () => {
    const { t } = useTranslation();
    const [countryId, setCountryId] = useState<number | null>(null);
    const [photos, setPhotos] = useState([null]);
    const navigate = useNavigate();
    const { data: countriesData } = useGetCountriesQuery();
    const { data: citiesData, loading: loadingCities, refetch: refetchCities } = useGetCitiesQuery({ variables: { countryId: countryId ?? 0 }, skip: !countryId });
    const { data: timezoneData } = useGetGmtQuery()
    const { data: languagesData } = useGetLanguagesQuery()
    const { data: currenciesData } = useGetCurrenciesQuery()
    const [createCompany] = useCreateCompanyBeautySalonMutation();
    const currentUserEmail = useReactiveVar(currentUserEmailVar)
    const [showCustomCityInput, setShowCustomCityInput] = useState<boolean>(false);

    const countryOptions = countriesData?.relations.countries.map((country) => ({
        label: country.name,
        value: country.id.toString(),
    })) || [];

    const cityOptions = citiesData?.relations.cities.map((city) => ({
        label: city.name,
        value: city.id,
    })) || [];

    cityOptions.push({ label: t('Other'), value: -1 });

    const loadCityOptions = async (inputValue: number, callback: (options: any) => void) => {
        if (!countryId) return callback([]);
        const response = await refetchCities({ countryId });
        const options = response.data.relations.cities.map((city: any) => ({
            label: city.name,
            value: city.id,
        }));
        callback(options);
    };

    const timezoneOptions = timezoneData?.relations.timezones.map((timezone) => ({
        label: timezone.name,
        value: timezone.id.toString(),
    })) || [];

    const languagesOptions = languagesData?.relations.languages.map((language) => ({
        label: language.name,
        value: language.id.toString(),
    })) || [];

    const currenciesOptions = currenciesData?.relations.currencies.map((currency) => ({
        label: `${currency.code} - ${currency.currency}`,
        value: currency.id.toString(),
    })) || [];

    const initialValues = {
        name: '',
        description: '',
        country: '',
        city: '',
        address: '',
        timezone: '',
        languages: '',
        messageLanguage: '',
        phone: '',
        currency: '',
        email: currentUserEmail || '',
        website: '',
        workSchedule: '',
        image: null,
        customCity: null,
    };

    const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
        const input = {
            category: "barber-shop",
            name: values.name,
            country: parseInt(values.country, 10),
            city: showCustomCityInput ? null : parseInt(values.city, 10),
            otherCity: values?.customCity ? values?.customCity : null,
            address: values.address,
            phone: values.phone,
            email: values.email,
            website: values.website,
            timezone: +values.timezone,
            currency: +values.currency,
            workSchedule: values.workSchedule,
            description: values.description,
            messageLanguage: +values.messageLanguage,
            images: photos,
            logo: values.image,
            deleted: false
        };

        const validPhotos = photos.filter(photo => photo != null)
        input.images = validPhotos;

        try {
            const response = await createCompany({
                variables: { input },
            });
            setSubmitting(false);
            navigate('/create-owner')
        } catch (error) {
            if (error?.graphQLErrors?.[0]?.extensions?.category === "company.already_exists") {
                setFieldError('name', t("Company with the received name and phone number already exists!"));
            }
            console.error('Error creating branch:', error);
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={beautySalonFormValidationSchema(t)}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
        >
            {({ setFieldValue, values, handleChange, handleBlur, isSubmitting, errors, touched }) => {
                const handleCityChange = (selectedOption: any) => {
                    if (selectedOption && selectedOption.value === -1) {
                        setShowCustomCityInput(true);
                        setFieldValue('city', '');
                    } else {
                        setShowCustomCityInput(false);
                        setFieldValue('city', selectedOption ? selectedOption.value : '');
                    }
                };
                return (
                    <Form>
                        <Row className="mt-6">
                            <Col lg={9} className="pe-lg-5">
                                <h5 className="fs-4 text-capitalize mb-4">{t("Basic information")}</h5>
                                <div className="pe-lg-4">
                                    <div className="mb-4">
                                        <Row className="align-items-center">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="registration-name-field" className="fs-7 fw-bold">{t('Company name')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="position-relative">
                                                    <Field id="registration-name-field" name="name" placeholder={t('Enter a company name')} className={`form-control rounded-1 lh-22 pe-5 text-truncate ${errors.name && touched.name ? "border-danger" : "border-secondary"}`} />
                                                    <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Col xs={9} md={10} className="ms-auto">
                                            <ErrorMessage name="name">{msg => <p className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </div>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-country-field" className="fs-7 fw-bold mt-2 pt-1">{t('country')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <ReactSelect
                                                id="registration-country-field"
                                                name='country'
                                                options={countryOptions}
                                                value={countryOptions.find((option: any) => option.value === values.country)}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue('city', '');
                                                    setFieldValue('country', selectedOption ? selectedOption.value : '');
                                                    setCountryId(selectedOption ? parseInt(selectedOption.value, 10) : null);
                                                }}
                                                placeholder={t('Select country')}
                                                isSearchable
                                            />
                                            <ErrorMessage name="country">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-city-field" className="fs-7 fw-bold mt-2 pt-1">{t('city')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <ReactAsyncSelect
                                                id="registration-city-field"
                                                name='city'
                                                key={`city-${values.country}`}
                                                options={cityOptions}
                                                loadOptions={loadCityOptions}
                                                value={cityOptions.find((option: any) => option.value === values.city)}
                                                onChange={handleCityChange}
                                                placeholder={t('Select City')}
                                                isSearchable
                                                isLoading={loadingCities}
                                            />
                                            <ErrorMessage name="city">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    {showCustomCityInput && (
                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="registration-custom-city-field" className="fs-7 fw-bold pt-2">{t('Add custom city name')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <Field
                                                    id="registration-custom-city-field"
                                                    name="customCity"
                                                    placeholder={t('Enter custom city name')}
                                                    className={`form-control ${errors.customCity && touched.customCity ? "border-danger" : "border-secondary"}`}
                                                    onChange={(e: any) => {
                                                        setFieldValue('customCity', e.target.value);
                                                    }}
                                                    maxLength={50}
                                                />
                                                <ErrorMessage name="customCity">{msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>
                                    )}

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-address-field" className="fs-7 fw-bold mt-2 pt-1">{t('address')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <div className="position-relative">
                                                <Field id="registration-address-field" name="address" placeholder={t('Enter your location')} className={`form-control rounded-1 lh-22 pe-5 ${errors.address && touched.address ? "border-danger" : "border-secondary"}`} />
                                                <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                        <i className="bi bi-info-circle"></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <ErrorMessage name="address">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-phone-field" className="fs-7 fw-bold mt-2 pt-1">{t('phone')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <PhonePicker
                                                id="registration-phone-field"
                                                name='phone'
                                            />
                                            <ErrorMessage name="phone">{msg => <p
                                                className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-email-field" className="fs-7 fw-bold mt-2 pt-1">{t('email')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <Field id="registration-email-field" name="email" placeholder={t('Add E-mail')} className="form-control rounded-1 lh-22" />
                                            <ErrorMessage name="email">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-website-field" className="fs-7 fw-bold mt-2 pt-1">{t('website')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <Field id="registration-website-field" name="website" placeholder={t('Site address')} className="form-control rounded-1 lh-22" />
                                            <ErrorMessage name="website">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    <div className="mb-4">
                                        <Row className="align-items-center">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="registration-timezone-field" className="fs-7 fw-bold">{t('timezone')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactSelect
                                                    id="registration-timezone-field"
                                                    name="timezone"
                                                    options={timezoneOptions}
                                                    value={timezoneOptions.find((option: any) => option.value === values.timezone)}
                                                    onChange={(selectedOption: any) => {
                                                        setFieldValue('timezone', selectedOption ? selectedOption.value : '');
                                                    }}
                                                    placeholder={t('Select Gmt')}
                                                    isSearchable
                                                />
                                            </Col>
                                        </Row>
                                        <Col xs={9} md={10} className="ms-auto">
                                            <ErrorMessage name="timezone">{msg => <p
                                                className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </div>

                                    <div className="mb-4">
                                        <Row className="align-items-center">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="registration-messageLanguage-field" className="fs-7 fw-bold">{t('Message language')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactSelect
                                                    id="registration-messageLanguage-field"
                                                    name="messageLanguage"
                                                    options={languagesOptions.filter(item => item.value === "2" || item.value === "8" || item.value === "9")}
                                                    value={languagesOptions.find((option: any) => option.value === values.messageLanguage)}
                                                    onChange={(selectedOption: any) => {
                                                        setFieldValue('messageLanguage', selectedOption ? selectedOption.value : '');
                                                    }}
                                                    placeholder={t('Select Language')}
                                                    isSearchable
                                                />
                                            </Col>
                                        </Row>
                                        <Col xs={9} md={10} className="ms-auto">
                                            <ErrorMessage name="messageLanguage">{msg => <p
                                                className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </div>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-currency-field" className="fs-7 fw-bold mt-2 pt-1">{t('Currency')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <ReactSelect
                                                id="registration-currency-field"
                                                name="currency"
                                                options={currenciesOptions}
                                                value={currenciesOptions.find((option: any) => option.value === values.currency)}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue('currency', selectedOption ? selectedOption.value : '');
                                                }}
                                                placeholder={t('Select Currency')}
                                                isSearchable
                                            />
                                            <ErrorMessage name="currency">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                        </Col>
                                    </Row>

                                    <div className="mb-4">
                                        <Row className="align-items-center">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="registration-workSchedule-field" className="fs-7 fw-bold">{t('Work schedule')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="position-relative">
                                                    <Field id="registration-workSchedule-field" name="workSchedule" placeholder={t('Work schedule')} className={`form-control rounded-1 lh-22 pe-5 ${errors.workSchedule && touched.workSchedule ? "border-danger" : "border-secondary"}`} />
                                                    <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Col xs={9} md={10} className="ms-auto">
                                            <ErrorMessage name="workSchedule">{msg => <p
                                                className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            <div className='mt-1 px-2'>
                                                <small className="text-grey">
                                                    {t('for example, Mon-Sun : 11:00-22:00')}
                                                </small>
                                            </div>
                                        </Col>
                                    </div>

                                    <Row className="mb-4">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-description-field" className="fs-7 fw-bold pt-2">{t('description')}</label>
                                        </Col>
                                        <Col xs={9} md={10} className="">
                                            <div className="position-relative">
                                                <Field as="textarea" id="registration-description-field" name="description" placeholder={t('description')} maxLength={200} className="form-control pe-5 rounded-1" />
                                                <div className="pt-1 position-absolute top-0 end-0 mt-1 me-3">
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                        <i className="bi bi-info-circle"></i>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <ErrorMessage name="description">{msg => <p
                                                className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            <div className="fs-7 text-grey text-start mt-1 px-1">
                                                {`${values.description.length}/200`}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mb-4 pt-6">
                                        <Col xs={3} md={2}>
                                            <label htmlFor="registration-photo-field" className="fs-7 fw-bold">{t('Photo of the branch')}</label>
                                        </Col>
                                        <Col xs={9} md={10}>
                                            <div className="mb-2">
                                                <p className="fs-7 mb-0">
                                                    {t("Add jpeg images of the branch premises, interior, etc.")}
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                        <i className="bi bi-info-circle ms-1 fs-7 pt-1"></i>
                                                    </OverlayTrigger>
                                                </p>
                                            </div>
                                            <Row className="gx-0">
                                                <DropPhotosMultiply
                                                    photosArray={photos}
                                                    setPhotos={setPhotos}
                                                />
                                            </Row>
                                            <div>
                                                <p className="fs-7 text-grey mb-6">{t("max-size")}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={3} className="ms-auto ps-lg-0">
                                <h5 className="fs-4 mb-4">{t("logo")}</h5>
                                <ImageCrop
                                    setFieldValue={setFieldValue}
                                    modalTitle={t('Company logo')}
                                />
                            </Col>
                        </Row>
                        <div className="my-4 mt-lg-0">
                            <button type="submit" className="btn btn-primary fw-bold custom-btn rounded-1" disabled={isSubmitting}>
                                {isSubmitting ? <SpinnerLoader variant="light" loadingText={t("Creating...")}/> : t('Create')}
                            </button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default React.memo(CreateCompanyForm);
