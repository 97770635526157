import React from 'react';

import styles from './joyrideTooltip.module.scss';

interface JoyrideTooltipProps {
    // step: Step;
    // close: () => void;
    // primaryProps: any;
    // skipProps: any;
    // backProps: any;
    children: React.ReactNode;
}

const JoyrideTooltip: React.FC<JoyrideTooltipProps> = ({ children }) => {
    const steper = parseInt(localStorage.getItem('step') || '0', 10);
    const maxSteps = 20;

    const calculatedWidth = (steper / maxSteps) * 100;
    const borderWidth = `${Math.max(calculatedWidth, 20)}%`;


    return (
        <div className={styles.tooltipContainer} style={{ '--border-width': borderWidth } as React.CSSProperties}>
            {children}
        </div>
    );
};

export default JoyrideTooltip;
