import { useTranslation } from 'react-i18next';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useCreateEmployeePositionMutation } from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import { EmployeePositionProxy } from '@me-team/host/main/graphql/types';
import React from 'react';

interface Option {
    id?: number;
    name?: string;
    value?: string;
    label?: string;
}

interface CreatePositionModalProps {
    show: boolean;
    handleModal: () => void;
    companyId: number;
    refetch: () => void;
    setNewPossition?: React.Dispatch<React.SetStateAction<EmployeePositionProxy>>;
    setFieldValue?: (field: string, value: string | number) => void;
    toggleShowToast?: (text: string) => void
}

const CreatePositionModal: React.FC<CreatePositionModalProps> = ({ show, handleModal, companyId, refetch, setNewPossition, setFieldValue, toggleShowToast}) => {
    const { t } = useTranslation();
    const [createPosition] = useCreateEmployeePositionMutation();

    const initialValues = {
        companyId: companyId,
        name: '',
        description: ''
    };
    const convertToOption = (employee: EmployeePositionProxy): any => {
        return {
            id: employee.id,
            name: employee.name,
            value: employee.name,
            label: employee.name
        };
    };
    const handleSubmit = async (values: typeof initialValues, { setFieldError }: FormikHelpers<typeof initialValues>) => {
        try {
            const response = await createPosition({
                variables: {
                    companyId: values.companyId,
                    name: values.name,
                    description: values.description
                }
            });
            const option = convertToOption(response.data.createEmployeePosition);
            refetch();
            if (setNewPossition) setNewPossition(option)
            if (setFieldValue) setFieldValue('employeePosition', response.data.createEmployeePosition.id)
            setTimeout(() => handleModal(), 0);
            toggleShowToast(t('Created'))
        } catch (error) {
            console.error('Error creating position:', error);
            const graphQLError = error.graphQLErrors?.[0].message;
            if (graphQLError === 'Employee position already exists') {
                setFieldError('name', t('Employee position already exists'));
            }
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().max(50, t('The maximum length of the Job Title field is 50 characters')).required(t('Fill this field')),
        description: Yup.string().max(400, t('The maximum length of the Description field is 400 characters'))
    });

    return (
        <>
            <Modal size="lg" show={show} onHide={handleModal} centered>
                <Modal.Header className="border-0" closeButton>
                    <h5 className="m-0"> {t('Create a new position')}</h5>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ values, errors, setFieldError }) => (
                            <Form>
                                <Row >
                                    <Col lg={2} xs={4} className="d-flex pr-0">
                                        <span className="text-dark fw-bold mt-1">
                                            <small>
                                                <label htmlFor="createPositionModal-name-field" className="mb-0 text-dark form-label required text-break"> {t('Positions name')}
                                                    <span className="text-danger ms-1 text-break">*</span>
                                                </label>
                                            </small>
                                        </span>
                                    </Col>
                                    <Col lg={10} xs={8}>
                                        <div className="position-relative">
                                            <div className="w-100">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    placeholder={t('Enter an employee position name')}
                                                    className={`fs-7 form-control ${errors.name ? 'is-invalid' : ''}`}
                                                    maxLength={50}
                                                    id="createPositionModal-name-field"
                                                />
                                            </div>

                                        </div>

                                    </Col>
                                    <Col lg={2} xs={4}></Col>
                                    <Col lg={10} xs={8}>
                                        <span className={`fs-7  ${values.name.length > 50 ? "text-danger" : "text-grey" } `}>
                                            {values.name.length}/50
                                        </span>
                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                    </Col>
                                </Row>

                                <Row className="pt-3 mb-3">
                                    <Col lg={2} xs={4} style={{ marginTop: '13px' }}>
                                        <span className="text-dark fw-bold">
                                            <small>
                                                <label htmlFor="createPositionModal-description-field" className="mb-0 text-dark text-break form-label required">{t('Description')}</label>
                                            </small>
                                        </span>
                                    </Col>
                                    <Col lg={10} xs={8}>
                                        <div className="position-relative">
                                            <div className="w-100">
                                                <Field
                                                    as="textarea"
                                                    className={`fs-7 form-control ${errors.description ? 'is-invalid' : ''}`}
                                                    name="description"
                                                    placeholder={t('Enter a position description')}
                                                    maxLength={400}
                                                    id="createPositionModal-description-field"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={2} xs={4}></Col>
                                    <Col lg={10} xs={8}>
                                        <span className={`fs-7 ${values.description.length > 400 ? "text-danger" : "text-grey"} `}>
                                            {values.description.length}/400
                                        </span>
                                        <ErrorMessage name="description" component="div" className="text-danger" />
                                    </Col>
                                </Row>
                                <Row className="d-flex justify-content-end pt-6">
                                    <Col xs={6} sm="auto" className=" mb-sm-0 pe-2 pe-sm-2" >
                                        <Button
                                            variant="outline-primary"
                                            className=" fw-normal custom-btn rounded-1"
                                            onClick={handleModal}
                                        >
                                            {t("Cancel")}
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm="auto" className="mb-sm-0 ps-2 ps-sm-2">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="fw-bold custom-btn rounded-1"
                                        >
                                            {t('Create')}
                                        </Button>
                                    </Col>

                                </Row>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreatePositionModal;
