import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Image from './image/connect-online.svg';
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {currentUserEmailVar, userIdVar, userUsernameVar} from "@me-team/host/src/apollo/globalVar/state";
import AccessService from "@me-team/host/services/AccessService";

interface ConnectModalProps {
    show: boolean;
    setOpenConnectModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConnectModal: React.FC<ConnectModalProps> = ({ show, setOpenConnectModal }) => {
    const navigate = useNavigate();
    const userId = useReactiveVar(userIdVar);
    const asd = useReactiveVar(userUsernameVar);
    const currentUserEmail = useReactiveVar(currentUserEmailVar);
    const handleClose = () => setOpenConnectModal(false);

    const start = (step: string) => {
        localStorage.setItem('step', step)
        localStorage.setItem('run', 'true')
        navigate('/employees-list');
    }
    const goToStep3 = (step: string) => {
        const accessService = AccessService.getInstance().isPremium
        start(accessService ?  '4' : '3')
        navigate('/employees-list', { state:  {  mail: currentUserEmail }})
    }
    return (
        <Modal show={show} onHide={handleClose} centered  >

            <Modal.Header className='border-0' closeButton>

            </Modal.Header>
            <Modal.Body className='text-center pt-0 pb-0'>
                <h5 className='fw-normal mb-3'>Підключення Онлайн-запису</h5>
                <Image />
                <p className='mb-0  mt-3'>Основні кроки по підключенню Онлайн-запису.</p>
                <p className='mb-0'>Оберіть кількість співробітників:</p>
            </Modal.Body>
            <Modal.Footer className='border-0 px-4 pt-4'>
                <Col >
                    <Button variant="primary" className="  w-100  "  onClick={()=> goToStep3('3')} >
                        Я працюю один
                    </Button>
                </Col>
                <Col>
                    <Button variant="success" className="btn btn-primary w-100 text-truncate " onClick={()=> start('1')}>
                        В мене є співробітник(и)
                    </Button>
                </Col>
                <Col xs={12} className='text-center '>
                    <Button variant="link" className='text-grey' onClick={handleClose}>
                    Пропустити Навчання
                    </Button>
                </Col>
            </Modal.Footer>

        </Modal>
    );
};

export default ConnectModal;
