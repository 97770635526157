import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";

const OnlineRecordStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={1} className="p-0 text-center">
                            <i className="bi bi-info-circle"></i>
                        </Col>
                        <Col xs={10} className="p-0">
                            <p className='fs-7 text-dark'>Налаштування "Онлайн-запис" дозволяє увімкнути або вимкнути
                                онлайн запис для закладу або всієї компанії.</p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-0 pt-3  border-top'>
                        <Col className='text-start p-0'>
                            <Button variant='outline-primary fw-normal' className="px-4"
                                    onClick={() => goToStep('back')}>Назад</Button>
                        </Col>
                        <Col className='text-end p-0'>
                            <Button className="px-4" onClick={() => goToStep('next')}>Далі</Button>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

export default OnlineRecordStep;
