import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {
    useCreateVacationMutation,
    useDeleteVacationMutation,
    useGetEmployeeByIdScheduleQuery,
} from '@me-team/host/main/work-shedule/graphql/workShedule.hooks';

interface Employee {
    id: number;
    surname: string;
    name: string;
    vacations: Array<{ id: number; startDate: string; endDate: string }>;
}

interface VacationModalProps {
    show: boolean;
    handleClose?: () => void;
    employeeId: number;
}

interface FormValues {
    startDate: Date | null;
    endDate: Date | null;
}

const setToEndOfDay = (date: Date): Date => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
};

const validationSchema = (vacations: Employee['vacations']) =>
    Yup.object()
        .shape({
            startDate: Yup.date().nullable().required('Start date is required'),
            endDate: Yup.date()
                .nullable()
                .required('End date is required')
                .min(Yup.ref('startDate'), 'End date cannot be before start date'),
        })
        .test(
            'date-overlap',
            'Vacation has already been added for the specified dates. Edit it.',
            function (values) {
                const { startDate, endDate } = values;
                if (!startDate || !endDate) return true;

                const startTimestamp = startDate.getTime();
                const endTimestamp = setToEndOfDay(endDate).getTime();

                for (let vac of vacations) {
                    const vacStart = new Date(vac.startDate.replace(' ', 'T'));
                    const vacEnd = setToEndOfDay(new Date(vac.endDate.replace(' ', 'T')));
                    const vacStartTimestamp = vacStart.getTime();
                    const vacEndTimestamp = vacEnd.getTime();
                    if (
                        (startTimestamp >= vacStartTimestamp &&
                            startTimestamp <= vacEndTimestamp) ||
                        (endTimestamp >= vacStartTimestamp &&
                            endTimestamp <= vacEndTimestamp) ||
                        (startTimestamp <= vacStartTimestamp &&
                            endTimestamp >= vacEndTimestamp)
                    ) {
                        return this.createError({
                            path: 'startDate',
                            message:
                                'Vacation has already been added for the specified dates. Edit it.',
                        });
                    }
                }
                return true;
            }
        );

const formatDate = (date: Date): string => {
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
};

const formatDateRange = (startDateStr: string, endDateStr: string): string => {
    const startDate = new Date(startDateStr.replace(' ', 'T') + 'Z');
    const endDate = new Date(endDateStr.replace(' ', 'T') + 'Z');

    return `${formatDate(startDate)} – ${formatDate(endDate)}`;
};

interface PreviousVacationsListProps {
    vacations: Employee['vacations'];
    onDelete: (vacationId: number) => void;
    t: (key: string) => string;
}

const PreviousVacationsList: React.FC<PreviousVacationsListProps> = ({ vacations, onDelete, t }) => {
    if (!vacations || !vacations.length) {
        return <span>{t('Missed')}</span>;
    }

    return (
        <>
            {vacations.map((vac, index) => (
                <div  className={`d-flex justify-content-between ${index !== 0 ? 'pt-3' : ''}`} key={vac.id}>
                    <span className="fs-7">{formatDateRange(vac.startDate, vac.endDate)}</span>
                    {new Date(vac.endDate) >= new Date() && (
                        <i
                            className="bi bi-trash3"
                            data-bs-title={t('Delete vacation')}
                            data-bs-placement="bottom"
                            onClick={() => onDelete(vac.id)}
                            style={{ cursor: 'pointer', width: '14px', height: '16px', fontSize: '14px' }}
                        ></i>
                    )}
                </div>
            ))}
        </>
    );
};

interface DatePickerFieldProps {
    id: string;
    name: string;
    label: string;
    selected: Date | null;
    onChange: (date: Date) => void;
    errorMessage: string | undefined;
    t: (key: string) => string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({ id, name, label, selected, onChange, errorMessage, t }) => (
    <Row className="pt-3">
        <Col xs={3} className="pe-0 pt-2">
            <label htmlFor={id}>
                <strong className="text-dark fs-7">{t(label)}</strong>
            </label>
        </Col>
        <Col xs={9} className="text-dark">
            <DatePicker
                id={id}
                name={name}
                className="form-control fs-7"
                wrapperClassName="w-100"
                selected={selected}
                placeholderText={t('dd.mm.yyyy')}
                onChange={onChange}
                dateFormat="dd.MM.yyyy"
            />
            {errorMessage && <p className="fs-7 p-1 text-danger m-0">{errorMessage}</p>}
        </Col>
    </Row>
);

const AddVacationModal: React.FC<VacationModalProps> = ({
                                                            show,
                                                            handleClose,
                                                            employeeId,
                                                        }) => {
    const { t } = useTranslation();
    const { data, loading, refetch } = useGetEmployeeByIdScheduleQuery({
        variables: { employeeId },
        context: {
            errorType: 'global',
        },
    });
    const [createVacation] = useCreateVacationMutation({
        context: {
            errorType: 'local',
        },
    });
    const [deleteVac] = useDeleteVacationMutation({
        context: {
            errorType: 'local',
        },
    });
    const employee = data?.user?.company?.employees?.employees[0];
    const [vacationsToDelete, setVacationsToDelete] = useState<number[]>([]);
    const [displayedVacations, setDisplayedVacations] = useState<Employee['vacations']>([]);

    useEffect(() => {
        if (employee?.vacations) {
            setDisplayedVacations(employee?.vacations);
        }
    }, [employee]);

    if (loading) {
        return (
            <Modal size="lg" show={true}>
                <Spinner />
            </Modal>
        );
    }

    const handleMarkVacationForDeletion = (vacationId: number) => {
        setVacationsToDelete((prev) => [...prev, vacationId]);
        setDisplayedVacations((prevVacations) =>
            prevVacations.filter((vac) => vac.id !== vacationId)
        );
    };

    const handleFormSubmit = async (values: FormValues, { resetForm }: any) => {
        try {
            await Promise.all(
                vacationsToDelete.map((vacationId) =>
                    deleteVac({
                        variables: { vacationId },
                    })
                )
            );

            if (values.startDate && values.endDate) {
                await createVacation({
                    variables: {
                        employeeId: employeeId,
                        input: {
                            startDate: values.startDate.toISOString().split('T')[0],
                            endDate: values.endDate.toISOString().split('T')[0],
                        },
                    },
                });
            }
            await refetch();
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0">{t('Add Vacation')}</h5>
            </Modal.Header>
            <Formik
                initialValues={{
                    startDate: null,
                    endDate: null,
                }}
                validationSchema={validationSchema(displayedVacations || [])}
                onSubmit={handleFormSubmit}
            >
                {({ setFieldValue, values, errors, touched }) => (
                    <Form noValidate>
                        <Modal.Body style={{ padding: '16px 28px' }}>
                            <Row className="py-3">
                                <Col xs={3} className="my-auto">
                                    <strong>
                                        <span className="fs-7">{t('Employee')}</span>
                                    </strong>
                                </Col>
                                <Col xs={9}>
                                    <span className="fs-7">
                                        {employee?.name} {employee?.surname}
                                    </span>
                                </Col>
                            </Row>

                            <DatePickerField
                                id="startDate"
                                name="startDate"
                                label={t('Start Date')}
                                selected={values.startDate}
                                onChange={(date) => setFieldValue('startDate', date)}
                                errorMessage={touched.startDate && errors.startDate ? errors.startDate : undefined}
                                t={t}
                            />

                            <DatePickerField
                                id="endDate"
                                name="endDate"
                                label={t('End Date')}
                                selected={values.endDate}
                                onChange={(date) => setFieldValue('endDate', date)}
                                errorMessage={touched.endDate && errors.endDate ? errors.endDate : undefined}
                                t={t}
                            />

                            <Row className='pt-3'>
                                <Col xs={3} className="pe-0">
                                    <label htmlFor="previousVacation">
                                        <strong className="text-dark fs-7">
                                            {t('Previous Vacation')}
                                        </strong>
                                    </label>
                                </Col>
                                <Col xs={9} className="text-dark">
                                    <PreviousVacationsList
                                        vacations={displayedVacations}
                                        onDelete={handleMarkVacationForDeletion}
                                        t={t}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div style={{ padding: '12px' }}>
                            <Row className="gx-3 justify-content-end">
                                <Col xs={3}>
                                    <Button
                                        variant="outline-dark"
                                        className="w-100 h-100 fw-normal"
                                        onClick={handleClose}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Col>
                                <Col xs={3}>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="w-100 text-truncate"
                                    >
                                        {t('Confirm')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddVacationModal;
