import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row} from 'react-bootstrap';
import {InfoBanner, TableHeader} from '@services/ui-components';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {CalendarFilterI} from './constants';
import CalendarWrapper from './CalendarWrapper';
import {
  currentBranchIdVar,
  currentUserRoleVar
} from '@me-team/host/src/apollo/globalVar/state';
import CalendarFilterWrapper from './CalendarFilterWrapper';
import {UserRole} from '../../utils/enums';
import ErrorService from '../../services/ErrorService';
import {useGetCurrentEmployeeIdQuery} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {useNavigate} from "react-router-dom";

const Logbook: React.FC = () => {
  const {t, i18n} = useTranslation();
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const basePath = process.env.REACT_APP_POS_URL;
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const navigate = useNavigate()

  const {data: currentEmployeeIdData, loading} = useGetCurrentEmployeeIdQuery({
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const currentEmployeeId = currentEmployeeIdData?.user?.employee?.id

  const initialValueFilter: CalendarFilterI = {
    statusId: null,
    positionId: null,
    employeeId: isEmployeeRole ? currentEmployeeId : null,
    serviceId: null,
  }

  const [filters, setFilters] = useState<CalendarFilterI>(initialValueFilter);
  const handleCreateRecord = () => {
    navigate('/logbook/add-record')
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <div className='px-6'>
        <InfoBanner>
          <p
            className='fs-7 mb-0'>{t('On this page, you can create, view, and edit customer records for the masters.')}
          </p>
        </InfoBanner>

        <TableHeader
          title={t('Logbook Journal')}
          colSize={9}
          btnColSize={3}
          buttons={
            <Row
              className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
              <Col
                xs={12}
                md={6}
                lg={7}
              >
                <Button
                  variant='green'
                  className='w-100 text-white mb-3 mb-lg-0 '
                  onClick={handleCreateRecord}
                >
                  <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                  {t('Add record')}
                </Button>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={5}
                className='px-0'
              >
                <Button
                  variant='additional-violet'
                  className=' fw-normal border-primary text-primary w-100 mb-3 mb-lg-0'
                  onClick={toggleFilters}
                >
                  <i className='bi bi-funnel me-1'></i>
                  {t('filters')}
                </Button>
              </Col>
            </Row>
          }
          showFilters={showFilters}
        >
          <CalendarFilterWrapper setFilters={setFilters}/>
        </TableHeader>
      </div>
      <CalendarWrapper filters={filters}/>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default Logbook;