import {CategoryInterface, SubcategoryInterface} from '../type';


class CategoryService {
  public static categoryModuleCreator = (serviceCategories : any[], categoryId : number | null, subcategoryId : number | null) : CategoryInterface[] => {
    const isActiveCategories = serviceCategories.filter((category : any) => !category.deleted);
    return isActiveCategories.map((serviceCategory) => {
      return {
        id: serviceCategory.id,
        name: serviceCategory.name,
        isOpen: !!((serviceCategory.id === categoryId) && subcategoryId),
        isPicked: !!((serviceCategory.id === categoryId) && !subcategoryId),
        isSubcategoryPiked : !!((serviceCategory.id === categoryId) && subcategoryId),
        subcategoryList: this.subcategoryModuleCreator(serviceCategory.childCategories, subcategoryId)
      }
    })
  }

  public static subcategoryModuleCreator = (subcategories : any[], currentSubcategoryId: number) : SubcategoryInterface[] => {
    const isActiveSubcategories = subcategories.filter((subcategory : any) => !subcategory.deleted)
    return isActiveSubcategories.map((subcategory) => {
      return {
        id: subcategory.id,
        name: subcategory.name,
        isPicked: subcategory.id === currentSubcategoryId
      }
    })
  }

}

export default CategoryService