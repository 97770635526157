import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
interface CommonQuestionsModalProps {
    setCommonQuestionsModal: (value: boolean) => void;
    commonQuestionsModal: boolean;
}

const CommonQuestionsModal: React.FC<CommonQuestionsModalProps> = ({ setCommonQuestionsModal, commonQuestionsModal }) => {

    return (
        <Modal show={commonQuestionsModal} onHide={() => setCommonQuestionsModal(false)} centered  >

            <Modal.Header className='border-0' closeButton>
                <h5 className='fw-normal'>Часті запитання</h5>
            </Modal.Header>
            <Modal.Body className='pt-2 pb-6'>
                <Row>
                    <Col>
                        <p className='fs-7 fw-bold  mb-2'>Чому філіал не відображається в онлайн записі?</p>
                        <ul className=" fs-7 mb-3">
                            <li>Онлайн запис для цієї компанії або філіалу вимкнено в налаштуваннях.</li>
                            <li>Немає робочого розкладу жодного з працівників, які мають включену онлайн запис хоча б на одну послугу.</li>
                        </ul>
                        <p className='fs-7 fw-bold  mb-2'>Чому послуга не відображається в онлайн записі?</p>
                        <ul className=" fs-7  mb-3">
                            <li>Онлайн запис для цієї послуги вимкнено.</li>
                            <li> Немає жодного виконавця для цієї послуги.</li>
                            <li>У всіх виконавців цієї послуги онлайн запис на неї вимкнено.</li>
                            <li> У всіх виконавців цієї послуги немає робочого розкладу.</li>
                        </ul>
                        <p className='fs-7 fw-bold mb-2'>Чому майстер не відображається в онлайн записі?</p>
                        <ul className=" fs-7 mb-2">
                            <li>Онлайн запис для цього майстра вимкнено.</li>
                            <li>У цього майстра немає робочого розкладу.</li>
                            <li>У цього майстра немає послуг, які він виконує.</li>
                            <li>У цього майстра всі послуги, які він виконує, мають вимкнену онлайн запис.</li>
                        </ul>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer className='border-0'>
                <Col>
                    <Button variant="primary" className="w-100" onClick={() => setCommonQuestionsModal(false)}>
                        Завершити
                    </Button>
                </Col>
            </Modal.Footer>

        </Modal>
    );
};

export default CommonQuestionsModal;
