import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {changeStepStorage} from "../joyrideCallbackHandler";
import StartStep from "./StartStep";
import {useState} from "react";

const ServiceProvidersStep: React.FC<StepFunctions> = ({ goToStep, booleanValue }) => {
    const navigate = useNavigate();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const handleMove = () => {
        changeStepStorage('next')
        navigate(`/booking/company/${currentCompanyId}/config`);
    };

    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={11}>
                            <p className='fs-7'><span className="fs-normal fw-bold fs-7">Оберіть співробітника</span> як
                                виконавця послуги </p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-0 pt-3  border-top'>
                        <Col className='text-end p-0'>
                            <Button variant={booleanValue ? "primary" : "secondary"} className="px-4"
                                    onClick={handleMove} disabled={!booleanValue}>Далі</Button>
                        </Col>

                    </Row>
                </>
            )}

        </>

    )
};

export default ServiceProvidersStep;

