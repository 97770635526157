import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import {useState} from "react";
import StartStep from "./StartStep";

const WorkScheduleStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <Row>
                    <Col xs={11}>
                        <p className='mb-0 fs-7'>Заповніть обов'язкові поля, позначені зірочкою (<span
                            className="text-danger">*</span>) та <span className="fs-normal fw-bold fs-7">натисніть "Додати" щоб зберегти нового співробітника</span>
                        </p>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg "></i>
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default WorkScheduleStep;  