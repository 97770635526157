import React, { useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import StartStep from "./StepsComponents/StartStep";


const MyFirstStepTooltip: React.FC = () => {
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <Row>
                    <Col xs={11}>
                        <span className="fs-normal fw-bold fs-7">Натисніть “Додати співробітника”</span>
                        <p className='mb-0 fs-7'>для відкриття форми створення нових співробітників</p>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default MyFirstStepTooltip;

