import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment/moment';
import {createStaticRanges, DateRangePicker} from 'react-date-range';
import {Col, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useTranslation} from 'react-i18next';
import useOnClickOutside from '@me-team/common/src/hooks/useOnClickOutside';

interface DateRangeCalendarProps {
  name?: string,
  isErrorDateRangeFilter?: boolean,
  dateRangeFilter: any,
  setDateRangeFilter: (dateRangeFilter: any) => void,
  onError?: (error: any) => void,
  id?: string;
  buttonRef?:any,
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

const DateRangeCalendar: React.FC<DateRangeCalendarProps> = ({
                                                               name,
                                                               isErrorDateRangeFilter,
                                                               dateRangeFilter,
                                                               setDateRangeFilter,
                                                               onError,
                                                               buttonRef,
                                                               id,
                                                               setFieldValue,
                                                               setFieldTouched
                                                             }) => {
  const {t} = useTranslation();
  const dateRangeRef = useRef(null)
  const [isValid, setIsValid] = React.useState(true);
  const EPOCH_TIME = moment(new Date(0)).format('YYYY-MM-DD')
  const formatDate = (date: any) => {
    return moment(date).format('YYYY-MM-DD')
  }

  useEffect(() => {
    if (onError) {
      onError(!isValid);
    }
  }, [isValid, onError]);

  useEffect(() => {
    isErrorDateRangeFilter && setIsValid(false)
  }, [isErrorDateRangeFilter]);

  const customStaticRanges = createStaticRanges([
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: moment().subtract(6, 'days').toDate(),
        endDate: new Date()
      })
    },
    {
      label: 'Last month',
      range: () => ({
        startDate: moment().subtract(1, 'month').startOf('month').toDate(),
        endDate: moment().subtract(1, 'month').endOf('month').toDate()
      })
    }
  ]);

  useEffect(() => {
    if (dateRangeFilter?.startDate && dateRangeFilter?.endDate) {
      setSelectionRange({
        startDate: new Date(dateRangeFilter.startDate),
        endDate: new Date(dateRangeFilter.endDate),
        key: 'selection'
      });
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [dateRangeFilter]);

  const initialSelectionRange = {
    startDate: new Date(dateRangeFilter?.startDate),
    endDate: new Date(dateRangeFilter?.endDate),
    key: 'selection',
  };

  const [selectionRange, setSelectionRange] = useState(initialSelectionRange);
  const [isDateRangePickerOpened, setIsDateRangePickerOpened] = useState(false);
  useOnClickOutside(dateRangeRef, () => setIsDateRangePickerOpened(false));
  const handleDateRangeChange = (ranges: any) => {
    const {selection: {startDate, endDate}} = ranges;
      setSelectionRange({
        startDate,
        endDate,
        key: 'selection',
      });
    setIsValid(true)
  };

  const handleShowClick = () => {
    if (selectionRange.startDate && selectionRange.endDate) {
      const newDateRange = {
        ...dateRangeFilter,
        startDate: formatDate(selectionRange.startDate),
        endDate: formatDate(selectionRange.endDate),
      };
      setDateRangeFilter(newDateRange);
      setIsValid(true);
      if (setFieldValue) setFieldValue('dateRangeFilter', newDateRange);
      if (setFieldTouched) setFieldTouched('dateRangeFilter', true, false);
    } else {
      setIsValid(false);
    }
    setIsDateRangePickerOpened(false);
  };

  const handleCancelClick = () => {
    setSelectionRange(initialSelectionRange);
    setIsValid(false);
    setIsDateRangePickerOpened(false);
  };

  return (
    <div
      className={`position-relative ${isValid ? 'px-3 border' : 'border-0'} h-100 border rounded  `}>
      <div className={`h-100 ${!isValid ? 'border border-danger rounded px-3' : '' }`}>
        <Button type="button"
                id={id}
                ref={buttonRef}
                onClick={() => setIsDateRangePickerOpened(!isDateRangePickerOpened)}
                className="button d-flex fw-normal justify-content-between align-items-center w-100 fs-7 border-0 bg-white text-dark px-0 pt-3"
        >
          {
            dateRangeFilter.endDate === EPOCH_TIME
              ?
              <span className='text-grey fw-normal'>{t('Select Period')}</span>
              :
              `${moment(dateRangeFilter.startDate).format("DD.MM.YY")} - ${moment(dateRangeFilter.endDate).format("DD.MM.YY")}`
          }
          {!isValid ?
            <i
              className="bi bi-exclamation-circle fs-20 position-absolute top-50 end-0 translate-middle-y text-danger p-3 pe-3 me-4 fw-bold"
            >
            </i> : null
          }
          <i className="bi bi-calendar4-week"></i>
        </Button>

      </div>

      {isDateRangePickerOpened &&
        <div ref={dateRangeRef}
             className="bg-white position-absolute start-0 border border-1 rounded-1 py-2 z-3">
        <div>
            <DateRangePicker
              onChange={handleDateRangeChange}
              ranges={[selectionRange]}
              shownDate={new Date()}
              rangeColors={['#F5E7F8', '#B3AEF0']}
              months={2}
              direction="horizontal"
              staticRanges={customStaticRanges}
              inputRanges={[]}
              dateDisplayFormat="MMMM d, yyyy"
              className='date-range-picker-wrapper'
              showDateDisplay={false}
              maxDate={new Date()}
            />
            <div
              className="fs-7 text-dark  d-flex justify-content-center flex-column flex-lg-row align-items-center justify-content-lg-end  border-top px-3 py-2">
              <small className='d-block'>
                {moment(selectionRange.startDate).format('MMMM D, YYYY')} - {moment(selectionRange.endDate).format('MMMM D, YYYY')}
              </small>
            </div>
          </div>
          <div className="border-top p-3 pb-2">
            <Row className="justify-content-end g-3">
              <Col xs={12} lg={3}>
                <Button variant='outline-dark'
                        className="w-100 d-flex justify-content-center"
                        onClick={handleCancelClick}
                >
                  <small>{t('cancel')}</small>
                </Button>
              </Col>
              <Col xs={12} lg={3}>
                <Button
                  variant='outline-primary'
                  className="w-100 d-flex justify-content-center"
                  onClick={handleShowClick}
                >
                  <small>{t('Show')}</small>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      }
    </div>

  );
};

export default DateRangeCalendar;